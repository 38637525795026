<template>
  <div class="w-full h-full -bg-slate-300">
    <div
      class="w-full h-full -bg-slate-300 overflow-y-auto border-l border-r invisible-scroolbar rounded"
    >
      <table class="min-w-full w-full -!bg-black relative leading-normal">
        <thead
          :style="{ backgroundColor: backgroundColor }"
          class="-bg-[#037950] w-full -bg-slate-500 sticky top-0 mrrounded-tl-xl mrrounded-tr-xl z-[999]"
          style="z-index: 50 !important"
        >
          <tr class="-bg-black flex w-full -bg-slate-300 -justify-between">
            <!-- v-show="column.visible" -->
            <!-- :class="[header == 'Ticker' ? '!w-[20%]' : '']" -->
            <!-- :class="
              column?.setIcon == 'setIcon'
                ? 'justify-between flex -bg-slate-500 -w-full px-0'
                : ''
            " -->
            <th
              v-for="(header, index) in table?.header"
              :key="index"
              :class="
                header == 'Corporate Etfs' ||
                header == 'Municipal Etfs' ||
                header == 'Government Etfs'
                  ? '!w-[18%]'
                  : ''
              "
              class="--px-5 relative text-[14px] w-[14%] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex justify-between items-center text-left text-white -uppercase -bg-white"
            >
              <div class="flex items-center gap-2 px-3">
                {{ header }}
                <!-- <span v-if="column?.icon" v-html="getSvg(column?.icon)"></span> -->
              </div>
              <div
                v-if="header == 'Charts' ? '' : 'hidden'"
                class="w-[0.2px] h-[38px] bg-black"
              />
            </th>
            <th
              class="absolute -bg-slate-300 justify-end flex h-full w-[250px] right-0"
            >
              <div class="relative flex items-center">
                <button @click="toggleDropdown" class="mr-5 text-white">
                  <span v-html="getSvg('setIcon')"></span>
                </button>
                <!-- Dropdown is here -->
                <div
                  v-if="showDropdown"
                  class="w-[65vh] absolute top-[40px] right-0 border rounded-bl rounded-br shadow-xl overflow-hidden h-[55vh] bg-white flex flex-col"
                >
                  <!-- v-if="!showDropdown" -->
                  <ColumnModal
                    @toggledropdown="toggleDropdown"
                    :columnsData="table?.header"
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="relative w-full min-w-full">
          <!--  -->
          <!-- :class="item?.id == 1 ? 'bg-[#d1fae2]' : 'bg-[white]'" -->
          <tr
            v-for="item in BondETFstableData[table.category]"
            :key="item.ticker"
            class="-bg-black border flex h-27"
          >
            <!-- ticker -->
            <td
              scope="col"
              class="py-2 w-[18%] flex justify-between items-center text-left text-black -uppercase"
            >
              <div class="flex flex-col gap-1 px-3">
                <div
                  class="flex text-[15px] leading-[15px] items-center gap-2 tracking-[.5px]"
                  style="font-weight: 550"
                >
                  {{ item.ticker }}
                  <span class="h-3 w-3" v-html="getSvg('share')"></span>
                </div>
                <div class="flex items-center gap-1">
                  <span class="text-[12px] leading-[15px]"
                    >${{
                      item?.ticker?.currentprice
                        ? item?.ticker?.currentprice
                        : "N/A"
                    }}</span
                  >

                  <div
                    :class="
                      item?.ticker?.change > 0
                        ? 'text-[#037950]'
                        : 'text-red-600'
                    "
                    class="flex !items-center gap-1"
                  >
                    <span
                      v-if="item?.ticker?.change > 0"
                      v-html="getSvg('tAritem')"
                    ></span>
                    <span
                      v-if="item?.ticker?.change < 0"
                      v-html="getSvg('dAritem1')"
                    ></span>
                    <span class="text-[12px] leading-[15px]">
                      {{
                        item?.change ? "(" + item?.change + "%)" : "( 1.17% )"
                      }}
                    </span>
                  </div>
                </div>
                <span
                  class="font-[400] line-clamp-1 leading-[15px] text-[13px] font-inter"
                  :class="
                    item?.ticker?.id == 1 ? 'text-[#8d8c8c]' : 'text-[#7A7A7A]'
                  "
                  >{{ item?.company ? item?.company : "N/A" }}</span
                >
              </div>
              <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
            </td>
            <!-- avg vol -->
            <!-- v-if="item?.avgVol?.isVisible" -->
            <td
              scope="col"
              class="py-3 w-[14%] flex justify-end items-center !text-right text-black -uppercase -bg-white"
            >
              <div class="flex flex-col -bg-slate-300 gap-1.5 px-3">
                <span
                  class="text-[#1d1d1d] leading-[15px] font-[500] text-[14px]"
                  >{{ item?.avgVol ? item?.avgVol : "N/A" }}</span
                >
                <span
                  :style="{ color: item?.avgVol?.color }"
                  class="text-[#8f8f8f] font-[400] leading-[13px] text-[14px]"
                  >{{ formatLargeNumber(item?.avgVol) }}</span
                >
              </div>
              <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
            </td>

            <!-- avg Dividend -->
            <!-- v-if="item?.avgDividend?.isVisible" -->
            <td
              scope="col"
              class="py-3 w-[14%] text-[14px] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex justify-end items-center !text-center text-black -uppercase -bg-white"
            >
              <div
                class="flex w-full justify-center items-center -bg-slate-300 gap-2 px-3"
              >
                <span
                  class="text-[#1d1d1d] leading-[15px] font-[500] text-[14px]"
                  >{{
                    item?.divPercent ? item?.divPercent.toFixed(2) : "N/A"
                  }}%</span
                >
              </div>
              <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
            </td>
            <!-- rating -->
            <!-- v-if="item?.rating?.isVisible" -->
            <td
              scope="col"
              class="py-3 w-[14%] text-[14px] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex justify-between items-center text-left text-black -uppercase -bg-white"
            >
              <div
                class="flex mx-auto flex-col justify-center gap-2 -bg-black --px-3"
              >
                <span class="">
                  {{ item?.ratings ? item?.ratings : "N/A" }}
                </span>
              </div>

              <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
            </td>
            <!-- optionable -->
            <!-- v-if="item?.optionable?.isVisible" -->
            <td
              scope="col"
              class="py-3 w-[14%] text-[14px] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex justify-between items-center text-left text-black -uppercase -bg-white"
            >
              <div
                class="flex w-full h-full pt-3.5 -bg-green-500 !items-center !justify-center px-3"
              >
                <!-- <span
                  class="px-2 gap-2 h-7 rounded-full text-[#056040] text-[13px] flex items-center justify-between bg-[#D1FAE2]"
                  v-if="item?.optionable?.optionable == 1"
                >
                  <span v-html="getSvg('checkIcon')"></span>
                  <span
                    class="w-5 h-5 rounded-full flex justify-center items-center -bg-red-400"
                  >
                    {{ item?.optionable?.optionable }}
                  </span>
                </span> -->
                <span class="" />
                {{ item?.optionable ? item?.optionable : "N/A" }}
              </div>
              <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
            </td>
            <!-- chart -->
            <td
              style=""
              scope="col"
              class="py-3 w-full text-[14px] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex flex-1 justify-center -bg-black items-center text-left text-black -uppercase -bg-white"
            >
              <div class="w-full flex -bg-green-300 flex-col gap-2 px-3">
                <div
                  class="w-full -bg-slate-300 py-2 h-[70px] flex justify-center items-center"
                >
                  <!-- <AreaChart /> -->
                  <img
                    class="w-full h-full object-contain"
                    :src="`https://incometradercharts.com/fmgraphttransparent.ashx?h=80&w=450&bw=3&sr=true&s=${item?.ticker}&int=true`"
                    alt=""
                  />
                </div>
              </div>
            </td>
          </tr>

          <div
            v-if="!isLoading && this.BondETFstableData.length < 1"
            class="flex justify-center items-center -bg-[#ECECEC] w-full h-[600px]"
          >
            <h1 class="text-[20px]">No result found!</h1>
          </div>
          <div
            v-if="isLoading"
            class="flex bg-white/50 justify-center items-center -absolute fixed top-[171px] w-[82%] h-[620px]"
          >
            <span class="loader"></span>
          </div>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { getSvgByName } from "../../assets/svgs/CurrentUptrend.js";
import ColumnModal from "../../components/ColumnOptionModal/index.vue";
export default {
  name: "GBAScanner",
  data() {
    return {
      showDropdown: false,
      isEnter: false,
      formattedTime: "",
      hiddenColumns: [],
      isCardView: false,
      // isActiveScanner: "GoodStocks",
      // isActive: "Good Stocks",
      allData: [],
      currentPage: 1,
      pageStart: 1,
      pageEnd: 50,
      itemsPerPage: 50,
    };
  },
  components: {
    ColumnModal,
  },

  props: {
    BondETFstableData: {
      type: Object,
      required: true,
    },
    table: {
      type: Object,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: "#F5F5F5",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getSvg(name) {
      return getSvgByName(name);
    },

    // Function to format numbers into K, M, B, or T and assign colors
    formatLargeNumber(number) {
      let formattedNumber;
      // let color;
      // alert(number);

      if (number >= 1e12) {
        // Trillions
        formattedNumber = (number / 1e12).toFixed(2) + "T";
        // color = "#8f8f8f"; // Green
      } else if (number >= 1e9) {
        // Billions
        formattedNumber = (number / 1e9).toFixed(2) + "B";
        // color = "#8f8f8f"; // Light Green
      } else if (number >= 1e6) {
        // Millions
        formattedNumber = (number / 1e6).toFixed(2) + "M";
        // color = "#8f8f8f"; // Yellow
      } else if (number >= 1e3) {
        // Thousands
        formattedNumber = (number / 1e3).toFixed(2) + "K";
        // color = "#8f8f8f"; // Orange
      } else {
        // Less than a thousand
        formattedNumber = number.toString();
        // color = "#8f8f8f"; // Red
      }

      return formattedNumber == 0 ? "N/A" : formattedNumber;
    },

    getDateOnly(dateString) {
      const date = new Date(dateString);
      return isNaN(date) ? "N/A" : date.getDate(); // Returns only the day of the month (1-31)
    },

    getMonthName(dateString) {
      const date = new Date(dateString);
      return isNaN(date)
        ? "N/A"
        : date.toLocaleString("default", { month: "short" }); // Returns the full month name
    },

    getYearOnly(dateString) {
      const date = new Date(dateString);
      return isNaN(date) ? "N/A" : date.getFullYear(); // Returns only the year (e.g., 2025)
    },

    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
      alert(this.tableOfPopularIndex[0]?.ticker?.title);
      console.log("toggleDropdown", this.tableOfPopularIndex);
    },
    toggleColumn(index) {
      this.columns[index].visible = !this.columns[index].visible;
      this.tableOfPopularIndex?.map((item) => {
        item[`column${index + 1}`].isVisible = this.columns[index].visible;
      });
    },
    // datalo() {
    //   this.allData = this.tableOfPopularIndex
    // },
  },
  computed: {
    //  this.datalo()
    // BondETFstableDat() {
    //   console.log("tableOfPopularIndex");
    //   return null;
    // },
  },
  watch: {
    BondETFstableData(val) {
      console.log("tableOfPopularIndex", val);
      return null;
    },
  },
};
</script>

<style></style>
