<template>
  <div class="flex flex-col h-full w-full justify-between p-2 gap-2">
    <div class="w-full h-[40px] flex gap-1 -bg-green-400">
      <div
        class="flex w-[49%] h-full items-center px-3 text-[11px] text-[#555F6D] bg-[#F4F4F4]"
      >
        SORTING
      </div>
      <div
        class="flex w-[49%] h-full items-center px-3 text-[11px] -text-[#555F6D] bg-[#F4F4F4]"
      >
        SHOW/HIDE COLUMN(S) - 1
      </div>
    </div>
    <div class="flex w-full h-[calc(100%-80px)] overflow-y-auto">
      <!-- Sort Options Start -->
      <div class="flex flex-col h-full w-[49%] gap-1">
        <div class="h-full -bg-slate-300">
          <div
            v-for="(item, index) in sortingBy"
            :key="index"
            class="flex h-12 w-full items-center"
          >
            <div
              class="flex flex-col w-full hover:cursor-pointer px-3 py-3 gap-2 hover:bg-[#00B475]/10"
              @click="sortName = item.title"
            >
              <div :key="item.id" class="flex w-full items-center gap-3">
                <svg
                  :class="
                    sortName == item.title ? 'fill-[#037950]' : 'fill-gray-300'
                  "
                  class="h-3.5 w-3.5"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM6.4 12L2.4 8L3.528 6.872L6.4 9.736L12.472 3.664L13.6 4.8L6.4 12Z"
                  />
                </svg>

                <span class="font-[200] text-[14.5px] text-gray-700">
                  {{ item.title }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Sort Options End -->

      <!-- Show / Hide Columns Start -->
      <div class="flex flex-col h-full w-[49%] gap-1">
        <div class="h-full -bg-slate-400">
          <div
            v-for="(item, index) in localColumnsData"
            v-show="localColumnsData.length > 0"
            :key="index"
            class="flex h-12 w-full items-center"
          >
            <div
              :class="
                item.title == 'Chart' || item.title == 'Ticker'
                  ? 'pointer-events-none bg-[#f3f0f0]'
                  : ''
              "
              class="flex flex-col w-full hover:cursor-pointer px-3 py-3 gap-2 hover:bg-[#00B475]/10"
              @click="toggleColumn(index, item.visible)"
            >
              <div :key="item.id" class="flex w-full items-center gap-3">
                <svg
                  :class="item.visible ? 'fill-[#037950]' : 'fill-gray-300'"
                  class="h-3.5 w-3.5"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM6.4 12L2.4 8L3.528 6.872L6.4 9.736L12.472 3.664L13.6 4.8L6.4 12Z"
                  />
                </svg>

                <span class="font-[200] text-[14.5px] text-gray-700">
                  {{ item.title }}
                </span>
              </div>
            </div>
          </div>

          <div
            v-for="(item, index) in localETFsColumnsData"
            v-show="localETFsColumnsData.length > 0"
            :key="index"
            class="flex h-12 w-full items-center"
          >
            <div
              :class="
                item?.title == 'Charts' || index == 0
                  ? 'pointer-events-none bg-[#f3f0f0]'
                  : ''
              "
              class="flex flex-col w-full hover:cursor-pointer px-3 py-3 gap-2 hover:bg-[#00B475]/10"
              @click="toggleColumnETFs(index, item?.title)"
            >
              <div :key="item.id" class="flex w-full items-center gap-3">
                <svg
                  :class="item?.visible ? 'fill-[#037950]' : 'fill-gray-300'"
                  class="h-3.5 w-3.5"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM6.4 12L2.4 8L3.528 6.872L6.4 9.736L12.472 3.664L13.6 4.8L6.4 12Z"
                  />
                </svg>

                <span class="font-[200] text-[14.5px] text-gray-700">
                  {{ item?.title }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Show / Hide Columns End -->
    </div>
    <!-- footer -->
    <div class="flex h-[40px] -bg-red-400 w-full items-end justify-end gap-3">
      <button
        @click="reset"
        class="flex items-center justify-center h-10 w-36 bg-[#F4F4F4] rounded text-[11px] text-[#555F6D]"
      >
        RESET TO DEFAULT
      </button>
      <button
        @click="closeModal"
        class="flex items-center justify-center h-10 w-20 bg-[#555F6D] rounded text-[11px] text-white"
      >
        CLOSE
      </button>
    </div>
  </div>
</template>

<script>
import ChartEvent from "../../eventBus.js";
export default {
  name: "ColumnModal",
  data() {
    return {
      sortingBy: [
        {
          id: 1,
          title: "Price High to Low",
          value: "high_to_low",
        },
        {
          id: 2,
          title: "Price Low to High",
          value: "low_to_high",
        },
        {
          id: 3,
          title: "Earning Date: Near to Far",
          value: "near_to_far",
        },
        {
          id: 4,
          title: "Earning Date: Far to Near",
          value: "far_to_near",
        },
        {
          id: 5,
          title: "Symbol A to Z",
          value: "a_to_z",
        },
        {
          id: 6,
          title: "Symbol Z to A",
          value: "z_to_a",
        },
        {
          id: 7,
          title: "Volume High to Low",
          value: "volume_high_to_low",
        },
        {
          id: 8,
          title: "Volume Low to High",
          value: "volume_low_to_high",
        },
        {
          id: 9,
          title: "Good, Average, Bad",
          value: "good_average_bad",
        },
      ],
      localColumnsData: JSON.parse(JSON.stringify(this.columnsData)), // Create a deep copy
      localETFsColumnsData: JSON.parse(JSON.stringify(this.columnsHeaderData)), // Create a deep copy
      selectedSort: false,
      sortName: "",
    };
  },
  props: {
    columnsData: {
      type: Array,
      default: () => [],
    },
    columnsHeaderData: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    reset() {
      this.sortName = "";
      this.ShowHideColumns.forEach((item) => {
        // alert(item.visible);
        item.isVisible = false;
        // item.status = false;
        // alert(item.isVisible);
      });
    },
    closeModal() {
      this.$emit("toggledropdown"); // Emit event as a string
    },

    toggleColumn(index) {
      this.localColumnsData[index].visible =
        !this.localColumnsData[index].visible;
      // alert(this.localColumnsData[index].visible);
      // alert("sheharyar");
      ChartEvent.$emit("toggleColumn", index);
    },

    toggleColumnETFs(index) {
      this.localETFsColumnsData[index].visible =
        !this.localETFsColumnsData[index].visible;
      console.log("hello data", this.localETFsColumnsData);
      console.log(
        "hello data visible",
        this.localETFsColumnsData[index].visible
      );
      // alert(this.localColumnsData[index].visible);
      // alert("sheharyar");
      ChartEvent.$emit("toggleColumnETFs", index);
    },
  },
  computed: {},
  watch: {
    columnsData: {
      handler(newVal) {
        console.log("hello data", this.localETFsColumnsData);
        // Update local copy when prop changes
        this.localColumnsData = JSON.parse(JSON.stringify(newVal));
      },
      deep: true,
    },

    columnsHeaderData: {
      handler(newVal) {
        console.log("hello data", this.localETFsColumnsData);
        // Update local copy when prop changes
        this.localETFsColumnsData = JSON.parse(JSON.stringify(newVal));
      },
      deep: true,
    },
  },
};
</script>
