<template>
  <div class="w-full h-full flex flex-col">
    <ul
      class="flex justify-center items-center gap-6 h-16 -border-b -bg-green-400"
    >
      <li
        class="cursor-pointer font-[500] border px-4 py-1 flex bg-[#EAEDF0] items-center rounded-full gap-2"
      >
        <span v-html="getSvg('subtraction')"></span>Font size<span
          v-html="getSvg('addition')"
        ></span>
      </li>
      <li
        class="cursor-pointer font-[500] border px-4 py-1 flex bg-[#EAEDF0] items-center rounded-full gap-2"
      >
        Jump to section<span v-html="getSvg('darrow')"></span>
      </li>
    </ul>
    <div
      class="w-full h-[calc(100%-64px)] -bg-slate-300 flex flex-col pt-3 pb-4 -justify-center items-center overflow-y-auto"
    >
      <div
        class="articleWrapper flex items-center flex-col w-[70%] h-auto rounded-2xl shadow-inner border -bg-green-400 px-4"
      >
        <div
          class="w-[85%] h-[100px] flex flex-col justify-center items-start -bg-slate-500"
        >
          <span class="font-[550] font-inter text-[#656565] text-[18px]"
            >COMMENTARY</span
          >
          <div class="w-[30px] h-[3.5px] bg-[#656565]"></div>
        </div>
        <div
          class="w-[85%] h-auto flex flex-col justify-center items-start -bg-red-500"
        >
          <h1 class="font-[550] leading-[60px] text-[40px] font-inter">
            A Comprehensive Analysis of Key Stocks
          </h1>
          <div
            class="w-full h-auto flex flex-col justify-center items-start -bg-red-500"
          >
            <p
              class="text-[18px] leading-[28px] tracking-[0.5px] font-inter text-[#4F4F4F]"
            >
              With the volatility in the current market, I thought it would be
              good to look at a few 'rules of thumb' for the short-term trader.
              This is not meant as a comprehensive list; it is just some
              thoughts on our part about trading.
            </p>
          </div>
        </div>

        <!-- author detail here -->

        <div
          class="w-[85%] h-[100px] flex flex-col border-b justify-center items-start -bg-red-500"
        >
          <div
            class="w-full h-20 flex -justify-center items-center gap-10 -bg-red-500"
          >
            <div
              class="flex items-center gap-2 text-[#3D3D3D] font-[500] text-[16px] leading-[18px] font-inter"
            >
              <span v-html="getSvg('calender')"></span>
              <span>Friday, 27th of January ,2024</span>
            </div>

            <div
              class="flex items-center gap-2 text-[#3D3D3D] font-[500] text-[16px] leading-[18px] font-inter"
            >
              <span v-html="getSvg('author')"></span>
              <span>Published by Mike Coval</span>
            </div>

            <div
              class="flex items-center gap-2 text-[#3D3D3D] font-[500] text-[16px] leading-[18px] font-inter"
            >
              <!-- <span v-html="getSvg('author')"></span> -->
              <span>5 min read</span>
            </div>
          </div>
        </div>

        <!-- actions and article detail -->

        <div
          class="w-[85%] h-[100px] flex border-b justify-between items-center -bg-red-500"
        >
          <!-- article detail actions -->
          <div
            class="w-auto h-20 flex -justify-center items-center gap-10 -bg-red-500"
          >
            <div
              class="flex items-center gap-2 text-[#CE1717] cursor-pointer px-5 py-3 bg-[#CE171714] font-[500] text-[16px] rounded-full leading-[18px] font-inter"
            >
              <span v-html="getSvg('playbtnicon')"></span>
              <!-- <span v-html="getSvg('pausebtnicon')"></span> -->
              <span>Play this article</span>
            </div>

            <div
              class="flex items-center gap-2 text-[#037950] cursor-pointer px-5 py-3 font-[500] text-[16px] bg-[#03795014] rounded-full leading-[18px] font-inter"
            >
              <span v-html="getSvg('printbtnicon')"></span>
              <span>Print this article</span>
            </div>
          </div>

          <!-- actions -->
          <div
            class="w-auto h-20 flex -justify-center items-center gap-10 -bg-red-500"
          >
            <div
              class="flex items-center gap-2 text-[#CE1717] cursor-pointer px-2.5 py-2.5 bg-[#F9F9F9] font-[500] text-[16px] rounded-lg leading-[18px] font-inter"
            >
              <span v-html="getSvg('copybtnicon')"></span>
            </div>

            <div
              class="flex items-center gap-2 text-[#037950] cursor-pointer px-2.5 py-2.5 font-[500] text-[16px] bg-[#F9F9F9] rounded-lg leading-[18px] font-inter"
            >
              <span v-html="getSvg('uploadbtnicon')"></span>
            </div>
          </div>
        </div>

        <div
          class="w-[85%] h-auto flex -border-b justify-between items-center -bg-red-500"
        >
          <div
            class="w-auto h-auto flex flex-col py-14 -justify-center items-center gap-10 -bg-red-500"
          >
            <p
              class="text-[18px] leading-[28px] tracking-[0.5px] font-inter text-[#4F4F4F]"
            >
              The first rule is that great companies only sometimes make great
              stocks. A significant stock increases in value at a healthy rate,
              giving the shareholders increased value. A lot of good companies
              are handling this recession in a manner that will make them
              stronger once it has ended and will resume increasing value. There
              are also many great companies out there that have a good business,
              but because that business is mature and has low growth, the stock
              will increase in value slowly. Some companies have corporate
              philosophies that make them less than attractive as a stock. These
              are companies in mature markets that are not looking to find ways
              to add additional higher-growth businesses but are just being the
              best in the industry that they are in.
            </p>

            <p
              class="text-[18px] leading-[28px] tracking-[0.5px] font-inter text-[#4F4F4F]"
            >
              The following rule is always to remember there is a difference
              between an investor and a speculator (or gambler). Even if you are
              trading for the short term, always remember that you are investing
              in companies. Do all the background homework to know the business
              that you are spending your money on. Yes, as a short-term trader,
              we rely on technical indications and will sell stocks that we
              like. But you are investing in a way that is managing risk, not
              speculating and hoping for the home-run stock break to come your
              way. This is especially true in today's extremely volatile market,
              as the 'home run' trade is just not very likely to happen for most
              traders.
            </p>

            <p
              class="text-[18px] leading-[28px] tracking-[0.5px] font-inter text-[#4F4F4F]"
            >
              The saying is "don't put all of your eggs in one basket. " In the
              market, this translates into putting only a little of your capital
              into one trade or stock. For a trader, the ideal is to have 2-3%
              of your money tied up in one trade. This is only sometimes
              practical for most of us with smaller accounts, but I advise never
              to get above 10% of your money in one position.
            </p>

            <p
              class="text-[18px] leading-[28px] tracking-[0.5px] font-inter text-[#4F4F4F]"
            >
              <span class="text-[20px] font-[600] text-black">Bottom Line</span>
              – Trading is somewhat of an art, but there is a science to it.
              Today’s suggestions deal with some of these science issues in
              trading. Remember that the more disciplined you are in your
              preparation, the more successful you will be as a trader. Good
              luck and good trading!
            </p>

            <p
              class="text-[18px] leading-[28px] tracking-[0.5px] font-inter text-[#4F4F4F]"
            >
              <span class="text-[20px] font-[600] text-black">Dow Jones</span>
              – The DOW closed at 37,806, down 57 points so far this week. It is
              trending back downward after hitting 38,000 intraday earlier in
              the week. The indicators are mixed, so we could make another try
              at this resistance level, but we suspect that it's more likely to
              go into a sideways pattern in the short term. We are taking a
              neutral outlook for now.
            </p>
          </div>
        </div>

        <!-- img of chart -->
        <div
          class="w-[85%] h-auto flex flex-col pb-5 border-b justify-between items-center -bg-red-500"
        >
          <!-- actions zoom -->
          <div
            class="w-full h-[50px] flex justify-between items-center -bg-red-500"
          >
            <div class="flex items-center gap-2.5">
              <span class="text-[28px] font-[600] font-inter">Tesla</span>
              <span
                class="px-3 py-[3px] bg-black text-white rounded-full font-inter text-[13px] leading-[15px]"
                >DAILY</span
              >
            </div>
            <div class="px-3 py-3 rounded-md cursor-pointer bg-[#F9F9F9]">
              <span v-html="getSvg('zoombtnicon')"></span>
            </div>
          </div>
          <!-- img -->
          <div
            class="w-full max-h-[400px] h-[400px] flex justify-between items-center bg-red-500"
          >
            <!-- <span v-html="getSvg('chartimg')"></span> -->
            <img
              class="w-full h-full"
              src="https://s3-alpha-sig.figma.com/img/1de8/aac0/61639f3083ff82c9c46637c24c890996?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=E8nHBqJgo5SP02Mc2b9SZSu6C54UtDqfTpq4sJl3xSdSh1nKaJFN3wgwU4devMzugpmrRhf8JX-j1nSMHaag8jTpg7cH12-UriK1yMMomNMH81cw3Zgut1b5CgmJp6jhkOXoTInrQ7eqNLBMrB~pyyuw3fILKcCTNMWb4~1yrc5jXdnWLauBJWHTQNGreuuz90-9PwobGgytliejr2E4HGZJhN250Frj6RKGQ1n10C8BuT-OJZlvbn18O~3Hu4dvNRBW4lP0x~8UcebrhjmyfUkuHOBPnHKR8jhCa22laRJDillov7PWT9iqulwC6vIXM-P2KNkpXt~7K3QA36HZYQ"
              alt=""
            />
          </div>
        </div>

        <div
          class="w-[85%] h-auto flex -border-b justify-between items-center -bg-red-500"
        >
          <div
            class="w-auto h-auto flex flex-col py-14 -justify-center items-center gap-10 -bg-red-500"
          >
            <p
              class="text-[18px] leading-[28px] tracking-[0.5px] font-inter text-[#4F4F4F]"
            >
              <span class="text-[20px] font-[600] text-black">NASDAQ</span>
              – The NASDAQ closed at 15,455, up 145 points for the week. After a
              very strong breakout two weeks ago, the NASDAQ looks to be picking
              up a bit of resistance right around 15,500 or so. With several
              NASDAQ mega-stocks reporting this week, we are hesitant to put in
              a bias one way or another, so we will stay mostly neutral and
              monitor the major earnings reports.
            </p>
          </div>
        </div>

        <!-- img of chart -->
        <div
          class="w-[85%] h-auto flex flex-col pb-5 border-b justify-between items-center -bg-red-500"
        >
          <!-- actions -->
          <div
            class="w-full h-[50px] flex justify-between items-center -bg-red-500"
          >
            <div class="flex items-center gap-2.5">
              <span class="text-[28px] font-[600] font-inter">NASDAQ</span>
              <span
                class="px-3 py-[3px] bg-black text-white rounded-full font-inter text-[13px] leading-[15px]"
                >DAILY</span
              >
            </div>
            <div class="px-3 py-3 rounded-md cursor-pointer bg-[#F9F9F9]">
              <span v-html="getSvg('zoombtnicon')"></span>
            </div>
          </div>
          <!-- img -->
          <div
            class="w-full max-h-[400px] h-[400px] flex justify-between items-center bg-red-500"
          >
            <!-- <span v-html="getSvg('chartimg')"></span> -->
            <img
              class="w-full h-full"
              src="https://s3-alpha-sig.figma.com/img/1de8/aac0/61639f3083ff82c9c46637c24c890996?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=E8nHBqJgo5SP02Mc2b9SZSu6C54UtDqfTpq4sJl3xSdSh1nKaJFN3wgwU4devMzugpmrRhf8JX-j1nSMHaag8jTpg7cH12-UriK1yMMomNMH81cw3Zgut1b5CgmJp6jhkOXoTInrQ7eqNLBMrB~pyyuw3fILKcCTNMWb4~1yrc5jXdnWLauBJWHTQNGreuuz90-9PwobGgytliejr2E4HGZJhN250Frj6RKGQ1n10C8BuT-OJZlvbn18O~3Hu4dvNRBW4lP0x~8UcebrhjmyfUkuHOBPnHKR8jhCa22laRJDillov7PWT9iqulwC6vIXM-P2KNkpXt~7K3QA36HZYQ"
              alt=""
            />
          </div>
        </div>

        <div
          class="w-[85%] h-[100px] flex border-b justify-between items-center -bg-red-500"
        >
          <!-- article detail -->
          <div
            class="w-auto h-20 flex -justify-center items-center gap-14 -bg-red-500"
          >
            <div
              class="flex items-center gap-2 text-[#000000]-bg-[#CE171714] font-[500] text-[16px] -rounded-full leading-[18px] font-inter"
            >
              <span v-html="getSvg('DavidMcKinlay')"></span>
              <div class="flex flex-col justify-center">
                <span>David McKinlay</span>
                <span class="text-[#888888] text-[14px] font-[500] leading-[16px]">Associate Editor</span>
              </div>
            </div>

            <div
              class="flex items-center gap-2 text-[#000000] font-[500] text-[16px] -bg-[#03795014] -rounded-full leading-[18px] font-inter"
            >
              <span v-html="getSvg('MikeCoval')"></span>
              <div class="flex flex-col justify-center">
                <span>Mike Coval</span>
                <span class="text-[#888888] text-[14px] font-[500] leading-[16px]">Editor</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//   import ChartVue from ".././AppexChart/ChartVue.vue";
import { getSvgByName } from "../../assets/svgs/article";

export default {
  name: "watchlistCard",
  components: {
    //   ChartVue,
  },
  data() {
    return {};
  },
  methods: {
    getSvg(name) {
      return getSvgByName(name);
    },
  },
};
</script>

<style scoped>
.articleWrapper {
  
  /* background-color: #f0f0f0; */
  box-shadow: 
    -5px 0 15px -5px rgba(0, 0, 0, 0.1), /* Left shadow */
     5px 0 15px -5px rgba(0, 0, 0, 0.1), /* Right shadow */
     0 -5px 15px -5px rgba(0, 0, 0, 0.1), /* Top shadow */
     0 5px 15px -5px rgba(0, 0, 0, 0.1); /* Bottom shadow */
}
</style>
