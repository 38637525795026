<template>
  <div class="w-full h-screen flex flex-col -bg-red-500">
    <LayoutPage />
  </div>
</template>

<script>
import LayoutPage from './components/LayoutPage/LayoutPage.vue';

export default {
  name: "App",

  data() {
    return {
      // backgroundColor: "#065036", // Default color
    };
  },
  components: {
    LayoutPage,
  },
  // mounted() {
  //   this.updateColorFromUrl();
  //   // Listen for URL changes if you allow URL updates dynamically
  //   window.addEventListener("popstate", this.updateColorFromUrl);
  // },
  // beforeDestroy() {
  //   // Clean up the event listener
  //   window.removeEventListener("popstate", this.updateColorFromUrl);
  // },
  // methods: {
  //   updateColorFromUrl() {
  //     // Extract color from the query string
  //     const urlParams = new URLSearchParams(window.location.search);
  //     const theme = urlParams.get("theme");

  //     console.log("theme", theme, urlParams);
  //     // Validate and update color
  //     if (theme == "1") {
  //       this.backgroundColor = "#555F6D";
  //     } else if (theme == "2") {
  //       this.backgroundColor = "#008053";
  //     } else if (theme == "3") {
  //       this.backgroundColor = "#161616";
  //     } else if (theme == "4") {
  //       this.backgroundColor = "#065036";
  //     } else {
  //       this.backgroundColor = "#065036";
  //     }
  //   },
  // },
};
</script>

<style>

</style>
