<template>
  <div class="w-full h-full relative flex flex-col -bg-red-500">
    <!-- header -->
    <div
      :class="isCardView ? 'h-[63px]' : 'h-[63px]'"
      class="w-full --py-2 gap-4 flex flex-col --pt-[10px] justify-center -bg-slate-500"
    >
      <!-- card view header and table view header is here -->
      <div
        class="flex justify-between h-full --mb-[6px] items-center gap-0 -bg-slate-300"
      >
        <!-- left side -->
        <div class="flex flex-col -items-center gap-3">
          <!-- <div v-if="isCardView" class="flex hidden gap-2 items-center">
            <span class="text-[#7C7C7C] text-[14px] font-inter font-[400]"
              >Scanners</span
            ><span v-html="getSvg('rightarrow')"></span
            ><span
              class="text-[#292929] text-[14px] font-[600] font-inter leading-[21px]"
              >3 Green Arrows</span
            >
          </div> -->
          <div class="flex flex-col -items-center gap-1">
            <span
              class="font-[500] text-[18px] gap-0 items-center flex font-inter leading-[18px] tracking-[0px]"
              ><span>Scanners</span>
              <span v-html="getSvg('rightArrow')"></span>
              {{ this.isActive }}</span
            >
            <div class="flex text-[9px] text-[#7C7C7C] leading-[12.7px]">
              <span>
                Last updated at:
                {{ formattedTime }}
              </span>
            </div>
          </div>
        </div>
        <!-- right side -->
        <div class="flex gap-3">
          <li
            ref="box"
            :class="isEnter ? 'border-[#161616] border-[1.5px]' : 'border'"
            class="flex border sm:w-[150px] xl:min-w-[200px] px-2 h-[38px] items-center !bg-[#f5f4f480] justify-around rounded"
          >
            <span class="cursor-pointer" v-html="getSvg('search')"> </span>
            <input
              class="w-[90%] h-full text-[13px] font-[500] text-[#888888] leading-[21px] bg-[transparent] decoration-none px-0.5 py-2 focus:outline-none ring-0"
              type="text"
              placeholder="Quick search"
            />
          </li>
          <!-- green background = volume spike -->
          <div class="flex items-center gap-3">
            <div
              class="flex bg-[#d1fae2] rounded gap-4 text-[#037950] min-w-[348px] --mt-6 border-[1.5px] text-[13px] leading-[16.94px] sm:px-2 xl:px-9 h-[38px] -border-[#037950] items-center"
            >
              <span v-html="getSvg('grExclamation')"></span>
              <span class="text-[#037950] font-[500] font-inter"
                >Green Background = Volume Spike</span
              >
            </div>
          </div>

          <!-- viewTogle btn-->
          <ul class="flex items-center list-none gap-4 underline-offset-0">
            <li
              @click="toggleView('card')"
              :class="
                isCardView
                  ? 'text-[#037950] bg-[#ECFDF4] border-[#037950]'
                  : '!text-[#000000] border-[#E5E5E5]'
              "
              class="flex gap-1 cursor-pointer h-[38px] items-center px-3 --py-1.5 rounded border"
            >
              <span v-html="getSvg('cardview')"></span>
              <span class="hidden">Card View</span>
            </li>
            <li
              @click="toggleView('table')"
              :class="
                !isCardView
                  ? '!text-[#037950] bg-[#ECFDF4] border-[#037950]'
                  : '!text-[#000000] border-[#E5E5E5]'
              "
              class="flex gap-1 cursor-pointer h-[38px] items-center px-3 py-0 rounded border"
            >
              <span v-html="getSvg('tableview')"></span
              ><span class="hidden">Table View</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- main data here -->
    <div class="w-full h-[calc(100%-62px)] -bg-slate-100">
      <!-- Dynamic Table -->
      <!-- <div class="h-[calc(100%-52px)] overflow-y-auto">
        <DynamicTable :DynamicTableData="DynamicTableData" :columns="columns" />
      </div> -->

      <!-- GoodStocks, BadStocks, AlmostGoodStocks -->
      <div
        v-if="
          !isCardView &&
          (isActiveScanner == 'GoodStocks' ||
            isActiveScanner == 'BadStocks' ||
            isActiveScanner == 'AlmostGoodStocks')
        "
        class="min-w-full h-[calc(100%-52px)] --relative -bg-slate-400"
      >
        <GBAScanner
          :rowsData="rows"
          :columnsData="columns"
          :backgroundColor="backgroundColor"
          :isLoading="isLoading"
          :totalResults="totalResults"
        />
      </div>

      <!-- CurrentTrend, UnderValuedStocks, OverValuedStocks, StellarFundamental -->
      <div
        v-if="
          !isCardView &&
          (isActiveScanner == 'CurrentTrend' ||
            isActiveScanner == 'UnderValuedStocks' ||
            isActiveScanner == 'OverValuedStocks' ||
            isActiveScanner == 'StellarFundamental')
        "
        class="min-w-full h-[calc(100%-52px)] relative -bg-slate-400"
      >
        <CUOSScanner
          :rowsData="rows"
          :columnsData="columns"
          :backgroundColor="backgroundColor"
          :isLoading="isLoading"
          :totalResults="totalResults"
        />
      </div>

      <!-- DividendStocks -->
      <div
        v-if="!isCardView && isActiveScanner == 'DividendStocks'"
        class="min-w-full h-[calc(100%-52px)] relative -bg-slate-400"
      >
        <DividendScanner
          :rowsData="rows"
          :columnsData="columns"
          :backgroundColor="backgroundColor"
          :isLoading="isLoading"
          :totalResults="totalResults"
        />
      </div>

      <!-- StockUnderTwenty, ThreeGreenArrows, ThreeRedArrows, Downsidebreakout, UpsideBreakout -->
      <div
        v-if="
          !isCardView &&
          (isActiveScanner == 'StockUnderTwenty' ||
            isActiveScanner == 'ThreeGreenArrows' ||
            isActiveScanner == 'ThreeRedArrows' ||
            isActiveScanner == 'Downsidebreakout' ||
            isActiveScanner == 'UpsideBreakout')
        "
        class="min-w-full h-[calc(100%-52px)] relative -bg-slate-400"
      >
        <GRU20Scanner
          :rowsData="rows"
          :columnsData="columns"
          :backgroundColor="backgroundColor"
          :isLoading="isLoading"
          :totalResults="totalResults"
        />
      </div>

      <!-- CoveredCallsCollars, EliteCoveredCalls -->
      <div
        v-if="
          !isCardView &&
          (isActiveScanner == 'CoveredCallsCollars' ||
            isActiveScanner == 'EliteCoveredCalls')
        "
        class="min-w-full h-[calc(100%-52px)] relative -bg-slate-400"
      >
        <ECScanner
          :rowsData="rows"
          :columnsData="columns"
          :backgroundColor="backgroundColor"
          :isLoading="isLoading"
          :totalResults="totalResults"
        />
      </div>

      <!-- EarningCalendar -->
      <div
        v-if="!isCardView && isActiveScanner == 'EarningCalendar'"
        class="min-w-full h-[calc(100%-52px)] relative -bg-slate-400"
      >
        <EarningCalendar
          :rowsData="rows"
          :columnsData="columns"
          :backgroundColor="backgroundColor"
          :isLoading="isLoading"
          :totalResults="totalResults"
        />
      </div>

      <!-- CanadianStocks -->
      <div
        v-if="!isCardView && isActiveScanner == 'CanadianStocks'"
        class="min-w-full h-[calc(100%-52px)] relative -bg-slate-400"
      >
        <CanadianScanner
          :rowsData="rows"
          :columnsData="columns"
          :backgroundColor="backgroundColor"
          :isLoading="isLoading"
          :totalResults="totalResults"
        />
      </div>

      <!-- EquityETFs, IncomeFundETFs, IntlEquityETFs, ReitETFs-->

      <div
        v-if="
          !isCardView &&
          (isActiveScanner == 'EquityETFs' ||
            isActiveScanner == 'IncomeFundETFs' ||
            isActiveScanner == 'IntlEquityETFs' ||
            isActiveScanner == 'ReitETFs')
        "
        class="min-w-full h-[calc(100%-52px)] relative -bg-slate-400"
      >
        <ETFsEIRIScanner
          :rowsData="rows"
          :columnsData="columns"
          :backgroundColor="backgroundColor"
          :isLoading="isLoading"
          :totalResults="totalResults"
        />
      </div>

      <!-- ETFs Scanner -->
      <!-- PopularETFs -->

      <div
        v-show="!isCardView && isActiveScanner == 'PopularETFs'"
        class="min-w-full h-[calc(100%-52px)] overflow-y-auto -bg-slate-400"
      >
        <div
          v-for="table in tableHeadData"
          :key="table.category"
          v-show="!isCardView && isActiveScanner == 'PopularETFs'"
          class="min-w-full h-auto --relative -bg-slate-400"
        >
          <PopularETFs
            :tableData="tableData"
            :table="table"
            :backgroundColor="backgroundColor"
            :isLoading="isLoading"
            :totalResults="totalResults"
          />
        </div>
      </div>

      <!-- ETFs Scanner -->
      <!-- BondETFs -->

      <div
        v-show="!isCardView && isActiveScanner == 'BondETFs'"
        class="min-w-full h-[calc(100%-52px)] overflow-y-auto -bg-slate-400"
      >
        <div
          v-for="table in BondEtfstableHeadData"
          :key="table.category"
          v-show="!isCardView && isActiveScanner == 'BondETFs'"
          class="min-w-full h-auto relative -bg-slate-400"
        >
          <BondETFs
            :BondETFstableData="BondETFstableData"
            :table="table"
            :backgroundColor="backgroundColor"
            :isLoading="isLoading"
            :totalResults="totalResults"
          />
        </div>
      </div>

      <!-- Table Footer here -->
      <div
        v-if="!isCardView"
        class="flex items-center border mrrounded-bl-xl mrrounded-br-xl h-[45px] px-5 --py-5 -bg-slate-500 xs:flex-row justify-between"
      >
        <div>
          <span class="text-[13px] text-[#807f7f] font-inter tracking-[0.5px]"
            >Showing {{ pageStart }} to {{ pageEnd }} of
            {{ totalResults }} stocks</span
          >
        </div>

        <div class="flex items-center">
          <!-- Pagination Buttons -->

          <!-- Previous Button -->
          <button
            @click="loadPreviousPage(currentPage)"
            :disabled="currentPage === 1"
            :class="currentPage === 1 ? 'text-[#c0bfbf]' : 'text-[#5e5d5d]'"
            class="w-full px-6 py-1 text-base font-[400] text-[13px] font-inter leading-[24px] tracking-[2px] bg-white border mrrounded-l-xl hover:bg-gray-100"
          >
            Previous
          </button>

          <!-- First Page -->
          <button
            @click="fetchDataByMidButton(1)"
            :class="
              currentPage === 1
                ? 'text-white bg-[#065036] hover:bg-[#086444]'
                : 'text-gray-600 bg-white hover:bg-gray-100'
            "
            class="w-full px-3 py-1 text-base border-t border-b"
          >
            1
          </button>

          <!-- Left Ellipsis -->
          <button
            v-if="showLeftEllipsis"
            type="button"
            class="w-full px-2 py-1 text-base text-gray-600 bg-white cursor-default border border-b hover:bg-gray-100"
          >
            <span v-html="getSvg('dotted')"></span>
          </button>

          <!-- Dynamic Buttons -->
          <button
            v-for="button in middleButtons"
            :key="button"
            @click="fetchDataByMidButton(button)"
            :class="
              button === currentPage
                ? 'text-white bg-[#065036] hover:bg-[#086444]'
                : 'text-gray-600 bg-white hover:bg-gray-100'
            "
            class="w-full px-3 py-1 text-base border"
          >
            {{ button }}
          </button>

          <!-- Right Ellipsis -->

          <button
            v-if="showRightEllipsis"
            type="button"
            class="w-full px-2 py-1 cursor-default text-base text-gray-600 bg-white border-t border-b hover:bg-gray-100"
          >
            <span v-html="getSvg('dotted')"></span>
          </button>

          <!-- Last Page -->
          <button
            @click="fetchDataByMidButton(totalPages)"
            :class="
              currentPage === totalPages
                ? 'text-white bg-[#065036] hover:bg-[#086444]'
                : 'text-gray-600 bg-white hover:bg-gray-100'
            "
            class="w-full px-3 py-1 text-base border"
          >
            {{ totalPages }}
          </button>

          <!-- Next Button -->
          <button
            @click="loadNextPage(currentPage + 1)"
            :disabled="currentPage === totalPages"
            :class="
              currentPage === totalPages ? 'text-[#c0bfbf]' : 'text-[#5e5d5d]'
            "
            class="w-full px-4 py-1 text-base bg-white border-t border-b border-r mrrounded-r-xl hover:bg-gray-100"
          >
            Next
          </button>
        </div>
      </div>
      <!-- card view here -->
      <div
        v-if="isCardView"
        class="w-full h-full flex justify-center items-center --!py-2 -bg-green-500"
      >
        <div
          class="w-full h-[98%] -bg-red-500 -border overflow-y-auto pb-2 invisible-scroolbar"
        >
          <CardView :cardData="cardData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSvgByName } from "../../assets/svgs/CurrentUptrend.js";
import CardView from "../card/CardView.vue";
// import AreaChart from "../AreaChart/AreaChart.vue";
import ChartEvent from "../../eventBus.js";
import GBAScanner from "./GBAScanner.vue";
import CUOSScanner from "./CUOSScanner.vue";
import GRU20Scanner from "./GRUDU20Scanner.vue";
import DividendScanner from "./DividendScanner.vue";
import ECScanner from "./ECScanner.vue";
import EarningCalendar from "./EarningCalendar.vue";
import CanadianScanner from "./CanadianScanner.vue";
import ETFsEIRIScanner from "./ETFsEIRIScanner.vue";
import PopularETFs from "./PopularETFs.vue";
import BondETFs from "./BondETFs.vue";
// import DynamicTable from "./DynamicTable.vue";
// import Tooltip from "../ToolTipVue/TooltipVue.vue";

export default {
  name: "ScannerVue",
  data() {
    return {
      showDropdown: false,
      isEnter: false,
      formattedTime: "",
      hiddenColumns: [],
      isCardView: false,
      isActiveScanner: "GoodStocks",
      isActive: "Good Stocks",
      DynamicTableData: [],
      allData: [],
      currentPage: 1,
      pageStart: 1,
      pageEnd: 50,
      itemsPerPage: 50,
      totalResults: 0,
      isLoading: false,
      hasMoreData: true,
      tablesOfPopularETFs: [],
      tableOfPopularIndex: [],
      cardData: [],
      rows: [],
      columns: [
        {
          id: 1,
          title: "Ticker",
          visible: true,
        },
        {
          id: 2,
          title: "Earning Date",
          visible: true,
        },
        {
          id: 3,
          title: "Sector",
          icon: "sector",
          visible: true,
        },
        {
          id: 4,
          title: "Industry",
          visible: true,
        },
        {
          id: 5,
          title: "Avg Vol",
          icon: "rExclamation",
          visible: true,
        },
        {
          id: 6,
          title: "Optionable",
          visible: true,
        },
        {
          id: 7,
          title: "FV",
          icon: "rExclamation",
          visible: true,
        },
        {
          id: 8,
          title: "ATP",
          icon: "rExclamation",
          visible: true,
        },
        {
          id: 9,
          title: "Chart",
          setIcon: "setIcon",
          visible: true,
        },
      ],

      // PopularETFs table head Data
      tableHeadData: [
        {
          header: [
            {
              title: "Indexes",
              visible: true,
            },
            {
              title: "Title",
              visible: true,
            },
            {
              title: "Optionable",
              visible: true,
            },
            {
              title: "Avg. Div",
              visible: true,
            },
            {
              title: "Ratings",
              visible: true,
            },
            {
              title: "Charts",
              visible: true,
            },
          ],
          category: "Index",
        },
        {
          header: [
            {
              title: "Inverse Indexes",
              visible: true,
            },
            {
              title: "Title",
              visible: true,
            },
            {
              title: "Optionable",
              visible: true,
            },
            {
              title: "Avg. Div",
              visible: true,
            },
            {
              title: "Ratings",
              visible: true,
            },
            {
              title: "Charts",
              visible: true,
            },
          ],
          category: "Inverse Index",
        },
        {
          header: [
            {
              title: "Sectors",
              visible: true,
            },
            {
              title: "Title",
              visible: true,
            },
            {
              title: "Optionable",
              visible: true,
            },
            {
              title: "Avg. Div",
              visible: true,
            },
            {
              title: "Ratings",
              visible: true,
            },
            {
              title: "Charts",
              visible: true,
            },
          ],
          category: "Sector",
        },
        {
          header: [
            {
              title: "High Yield Dividends ETFs",
              visible: true,
            },
            {
              title: "Title",
              visible: true,
            },
            {
              title: "Optionable",
              visible: true,
            },
            {
              title: "Avg. Div",
              visible: true,
            },
            {
              title: "Ratings",
              visible: true,
            },
            {
              title: "Charts",
              visible: true,
            },
          ],
          category: "High Yield Dividend ETF",
        },
      ],

      // Bond ETFs table head Data
      BondEtfstableHeadData: [
        {
          header: [
            "Corporate Etfs",
            "Avg Volume",
            "Avg. Div",
            "Ratings",
            "Optionable",
            "Charts",
          ],
          category: "Corporate Etfs",
        },
        {
          header: [
            "Municipal Etfs",
            "Avg Volume",
            "Avg. Div",
            "Ratings",
            "Optionable",
            "Charts",
          ],
          category: "Municipal Etfs",
        },
        {
          header: [
            "Government Etfs",
            "Avg Volume",
            "Avg. Div",
            "Ratings",
            "Optionable",
            "Charts",
          ],
          category: "Government Etfs",
        },
      ],
      // Bond ETFs table data
      BondETFstableData: {
        "Corporate Etfs": [
          {
            ticker: "IWM",
            avgVol: 44219900,
            divPercent: 1.568,
            company: "iShares Russell 2000 ETF",
            ratings: "",
            category: "Corporate Etfs",
          },
          {
            ticker: "SPLG",
            avgVol: 6124480,
            divPercent: 1.492,
            company: "SPDR Portfolio S&P 500 ETF",
            ratings: "",
            category: "Corporate Etfs",
          },
          {
            ticker: "SPY",
            avgVol: 0,
            divPercent: 0,
            company: "",
            ratings: "",
            category: "Corporate Etfs",
          },
          {
            ticker: "QQQ",
            avgVol: 0,
            divPercent: 0,
            company: "",
            ratings: "",
            category: "Corporate Etfs",
          },
          {
            ticker: "DIA",
            avgVol: 0,
            divPercent: 0,
            company: "",
            ratings: "",
            category: "Corporate Etfs",
          },
        ],
        "Municipal Etfs": [
          {
            ticker: "XLF",
            avgVol: 40790100,
            divPercent: 1.948,
            company: "Financial Select Sector SPDR",
            ratings: "",
            category: "Municipal Etfs",
          },
          {
            ticker: "XLE",
            avgVol: 20688900,
            divPercent: 3.663,
            company: "The Energy Select Sector SPDR Fund",
            ratings: "",
            category: "Municipal Etfs",
          },
          {
            ticker: "XLU",
            avgVol: 20531300,
            divPercent: 3.384,
            company: "Utilities Select Sector SPDR ETF",
            ratings: "",
            category: "Municipal Etfs",
          },
          {
            ticker: "XLP",
            avgVol: 13652300,
            divPercent: 2.689,
            company: "Consumer Staples Select Sector SPDR",
            ratings: "",
            category: "Municipal Etfs",
          },
          {
            ticker: "XLV",
            avgVol: 11091200,
            divPercent: 1.647,
            company: "Health Care Select Sector SPDR",
            ratings: "",
            category: "Municipal Etfs",
          },
          {
            ticker: "XLI",
            avgVol: 9835730,
            divPercent: 1.629,
            company: "Industrial Select Sector SPDR",
            ratings: "",
            category: "Municipal Etfs",
          },
          {
            ticker: "XRT",
            avgVol: 8216550,
            divPercent: 2.018,
            company: "SPDR Retail ETF",
            ratings: "",
            category: "Municipal Etfs",
          },
          {
            ticker: "XLK",
            avgVol: 7179110,
            divPercent: 0.756,
            company: "Technology Select Sector SPDR ETF",
            ratings: "",
            category: "Municipal Etfs",
          },
          {
            ticker: "XLB",
            avgVol: 5913480,
            divPercent: 2.049,
            company: "Materials Select Sector SPDR",
            ratings: "",
            category: "Municipal Etfs",
          },
        ],
        "Government Etfs": [
          {
            ticker: "PSQ",
            avgVol: 25084200,
            divPercent: 4.225,
            company: "ProShares Short QQQ -1x Shares",
            ratings: "",
            category: "Government Etfs",
          },
          {
            ticker: "SDS",
            avgVol: 7298640,
            divPercent: 3.831,
            company: "ProShares UltraShort S&P500 -2x Shares",
            ratings: "",
            category: "Government Etfs",
          },
          {
            ticker: "RWM",
            avgVol: 2481420,
            divPercent: 3.136,
            company: "ProShares Short Russell2000 -1x Shares",
            ratings: "",
            category: "Government Etfs",
          },
        ],
      },
      // popular ETFs table data
      tableData: {
        Index: [
          {
            ticker: "IWM",
            avgVol: 44219900,
            divPercent: 1.568,
            company: "iShares Russell 2000 ETF",
            ratings: "",
            category: "Index",
          },
          {
            ticker: "SPLG",
            avgVol: 6124480,
            divPercent: 1.492,
            company: "SPDR Portfolio S&P 500 ETF",
            ratings: "",
            category: "Index",
          },
          {
            ticker: "SPY",
            avgVol: 0,
            divPercent: 0,
            company: "",
            ratings: "",
            category: "Index",
          },
          {
            ticker: "QQQ",
            avgVol: 0,
            divPercent: 0,
            company: "",
            ratings: "",
            category: "Index",
          },
          {
            ticker: "DIA",
            avgVol: 0,
            divPercent: 0,
            company: "",
            ratings: "",
            category: "Index",
          },
        ],
        Sector: [
          {
            ticker: "XLF",
            avgVol: 40790100,
            divPercent: 1.948,
            company: "Financial Select Sector SPDR",
            ratings: "",
            category: "Sector",
          },
          {
            ticker: "XLE",
            avgVol: 20688900,
            divPercent: 3.663,
            company: "The Energy Select Sector SPDR Fund",
            ratings: "",
            category: "Sector",
          },
          {
            ticker: "XLU",
            avgVol: 20531300,
            divPercent: 3.384,
            company: "Utilities Select Sector SPDR ETF",
            ratings: "",
            category: "Sector",
          },
          {
            ticker: "XLP",
            avgVol: 13652300,
            divPercent: 2.689,
            company: "Consumer Staples Select Sector SPDR",
            ratings: "",
            category: "Sector",
          },
          {
            ticker: "XLV",
            avgVol: 11091200,
            divPercent: 1.647,
            company: "Health Care Select Sector SPDR",
            ratings: "",
            category: "Sector",
          },
          {
            ticker: "XLI",
            avgVol: 9835730,
            divPercent: 1.629,
            company: "Industrial Select Sector SPDR",
            ratings: "",
            category: "Sector",
          },
          {
            ticker: "XRT",
            avgVol: 8216550,
            divPercent: 2.018,
            company: "SPDR Retail ETF",
            ratings: "",
            category: "Sector",
          },
          {
            ticker: "XLK",
            avgVol: 7179110,
            divPercent: 0.756,
            company: "Technology Select Sector SPDR ETF",
            ratings: "",
            category: "Sector",
          },
          {
            ticker: "XLB",
            avgVol: 5913480,
            divPercent: 2.049,
            company: "Materials Select Sector SPDR",
            ratings: "",
            category: "Sector",
          },
        ],
        "Inverse Index": [
          {
            ticker: "PSQ",
            avgVol: 25084200,
            divPercent: 4.225,
            company: "ProShares Short QQQ -1x Shares",
            ratings: "",
            category: "Inverse Index",
          },
          {
            ticker: "SDS",
            avgVol: 7298640,
            divPercent: 3.831,
            company: "ProShares UltraShort S&P500 -2x Shares",
            ratings: "",
            category: "Inverse Index",
          },
          {
            ticker: "RWM",
            avgVol: 2481420,
            divPercent: 3.136,
            company: "ProShares Short Russell2000 -1x Shares",
            ratings: "",
            category: "Inverse Index",
          },
        ],
        "High Yield Dividend ETF": [
          {
            ticker: "IDV",
            avgVol: 860951,
            divPercent: 6.939,
            company: "iShares International Select Dividend ETF",
            ratings: "",
            category: "High Yield Dividend ETF",
          },
          {
            ticker: "DVY",
            avgVol: 753597,
            divPercent: 3.958,
            company: "iShares Select Dividend ETF",
            ratings: "",
            category: "High Yield Dividend ETF",
          },
          {
            ticker: "SDY",
            avgVol: 539900,
            divPercent: 2.723,
            company: "SPDR Dividend ETF",
            ratings: "",
            category: "High Yield Dividend ETF",
          },
          {
            ticker: "SDIV",
            avgVol: 181710,
            divPercent: 11.897,
            company: "Global X SuperDividend ETF",
            ratings: "",
            category: "High Yield Dividend ETF",
          },
          {
            ticker: "DIV",
            avgVol: 129084,
            divPercent: 7.602,
            company: "Global X SuperDividend U.S. ETF",
            ratings: "",
            category: "High Yield Dividend ETF",
          },
        ],
      },
    };
  },
  components: {
    CardView,
    GBAScanner,
    CUOSScanner,
    DividendScanner,
    GRU20Scanner,
    ECScanner,
    EarningCalendar,
    CanadianScanner,
    ETFsEIRIScanner,
    PopularETFs,
    BondETFs,
    // DynamicTable,
  },
  props: {
    backgroundColor: String,
  },
  methods: {
    getSvg(name) {
      return getSvgByName(name);
    },

    capitalizeWords(str) {
      return str
        .toLowerCase() // Convert the entire string to lowercase
        .split(" ") // Split the string into words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
        .join(" "); // Join the words back into a single string
    },

    updateTime() {
      const date = new Date();

      // Format the date and time
      const formattedDate = `${String(date.getUTCMonth() + 1).padStart(
        2,
        "0"
      )}/${String(date.getUTCDate()).padStart(2, "0")}`;
      const hours = date.getUTCHours();
      const minutes = String(date.getUTCMinutes()).padStart(2, "0");
      const period = hours >= 12 ? "pm" : "am";
      const formattedTime = `${hours % 12 || 12}:${minutes}${period}`;

      // Update the component's data
      this.formattedTime = `${formattedDate} ${formattedTime} UTC`;
    },

    async loadNextPage(page) {
      this.goToPage(page);
      this.fetchData(this.currentPage);
      // alert(this.currentPage);
      // this.currentPage = 1;
      // alert(this.currentPage);
      this.pageStart += this.itemsPerPage;
      this.pageEnd += this.itemsPerPage;
      console.log("next", this.currentPage);
    },

    loadPreviousPage(page) {
      this.goToPage(page);
      // alert(this.currentPage);
      if (this.currentPage > 1) {
        this.fetchData(this.currentPage);
        this.currentPage--;
        // alert(this.currentPage);
        this.pageStart = this.pageStart - this.itemsPerPage;
        this.pageEnd = this.pageEnd - this.itemsPerPage;
      } else {
        this.fetchData(1);
      }
    },

    fetchDataByMidButton(page) {
      // Har page par items ki count (e.g., 50 items per page)
      // alert(page);
      const itemsPerPage = 50;

      // Calculate pageStart based on pageNumber
      this.pageStart = (page - 1) * itemsPerPage + 1;

      // Calculate pageEnd based on pageStart
      this.pageEnd = this.pageStart + itemsPerPage - 1;

      console.log(
        `Input Page: ${page} -> pageStart: ${this.pageStart}, pageEnd: ${this.pageEnd}`
      );
      this.fetchData(page);
    },

    async fetchData(page) {
      this.goToPage(page);
      this.isLoading = true;
      // Local api            // http://192.168.0.64:5096/api/ScannerApi/${this.isActiveScanner}?pagestart=${page}&pageend=${this.itemsPerPage}

      // Live api // https://core.successstockmarket.com/api/ScannerApi/${this.isActiveScanner}?pagestart=${page}&pageend=${this.itemsPerPage}

      try {
        const response = await fetch(
          `https://core.successstockmarket.com/api/ScannerApi/${this.isActiveScanner}?pagestart=${page}&pageend=${this.itemsPerPage}`
          // `http://192.168.0.64:5096/api/ScannerApi/${this.isActiveScanner}?pagestart=${page}&pageend=${this.itemsPerPage}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        this.DynamicTableData = data?.data?.result[0]?.data;
        console.log("this.tableData", this.DynamicTableData);
        this.cards = data;
        // console.log("hello ETFs", typeof ((response.data?.result[0]?.data)));
        // console.log("hello ETFs", Object.keys(response.data?.result[0]?.data));
        // console.log("rows before", this.rows);
        let xyz = [];
        if (this.isActiveScanner == "PopularETFs") {
          this.tablesOfPopularETFs = Object.keys(data?.data?.result[0]?.data);
          console.log("this.tablesOfPopularETFs", this.tablesOfPopularETFs);

          this.tableData = data?.data?.result[0]?.data;
          console.log("this.tablesOfPopularETFsIndex", this.tableData);

          let something = Object.keys(this.tableData["Index"][0]);
          console.log("something", something);

          this.tablesOfPopularETFs.forEach((element) => {
            let abc = {
              hedear: [],
              category: null,
            };

            something.forEach((item) => {
              // console.log(item);
              let test = {
                title: item !== "ticker" ? item : null,
                visible: item == "ticker" ? false : true,
              };
              abc.hedear.push(test);
            });
            abc.category = element;
            xyz.push(abc);
          });

          console.log("something", xyz);

          // for (let i = 0; i < this.tablesOfPopularETFs.length; i++) {
          //   let abc = {
          //     hedear: [{}],
          //   };
          // }
        } else if (this.isActiveScanner == "BondETFs") {
          this.tablesOfBondETFs = data?.data?.result[0]?.data;
        }
        this.columns = this.transformColumnsData(this.isActiveScanner);
        this.rows = this.transformData(data, this.isActiveScanner);
        this.cardData = this.transformcardData(data);
        // console.log("rows after", this.rows);
        // console.log("rows cardData", this.cardData);
        this.isLoading = false;
        return data.items; // Adjust based on your API's response structure
      } catch (error) {
        console.error("API error:", error);
        this.isLoading = false;
        return [];
      } finally {
        this.isLoading = false;
      }
    },

    // new function of goto page

    goToPage(page) {
      if (page > 0 && page <= this.totalPages) {
        console.log("page", page);
        this.currentPage = page;
      }
    },

    toggleView(view) {
      if (view == "card") {
        this.isCardView = true;
      } else {
        this.isCardView = false;
      }
    },

    // formatLargeNumber(value) {
    //   if (value >= 1_000_000_000_000) {
    //     return (value / 1_000_000_000_000).toFixed(0) + "T";
    //   } else if (value >= 1_000_000_000) {
    //     return (value / 1_000_000_000).toFixed(0) + "B";
    //   } else if (value >= 1_000_000) {
    //     return (value / 1_000_000).toFixed(0) + "M";
    //   } else if (value >= 10_000) {
    //     return (value / 1_000).toFixed(0) + "K";
    //   } else {
    //     return value.toString();
    //   }
    // },

    // Function to format numbers into K, M, B, or T and assign colors
    formatLargeNumber(number) {
      let formattedNumber;
      let color;

      if (number >= 1e12) {
        // Trillions
        formattedNumber = (number / 1e12).toFixed(2) + "T";
        color = "#8f8f8f"; // Green
      } else if (number >= 1e9) {
        // Billions
        formattedNumber = (number / 1e9).toFixed(2) + "B";
        color = "#8f8f8f"; // Light Green
      } else if (number >= 1e6) {
        // Millions
        formattedNumber = (number / 1e6).toFixed(2) + "M";
        color = "#8f8f8f"; // Yellow
      } else if (number >= 1e3) {
        // Thousands
        formattedNumber = (number / 1e3).toFixed(2) + "K";
        color = "#8f8f8f"; // Orange
      } else {
        // Less than a thousand
        formattedNumber = number.toString();
        color = "#8f8f8f"; // Red
      }

      return { value: formattedNumber, color: color };
    },

    transformColumnsData(scannerType) {
      if (
        scannerType == "GoodStocks" ||
        scannerType == "BadStocks" ||
        scannerType == "AlmostGoodStocks"
      ) {
        return [
          {
            id: 1,
            title: "Ticker",
            icon: "rExclamation",
            tooltip: "Stock's Ticker",
            tooltip1:
              "Stock's Ticker Symbol, Company Name, Current Price, Change, Earning Date.",
            visible: true,
          },

          {
            id: 3,
            title: "Sector",
            // icon: "sector",
            visible: true,
          },
          {
            id: 4,
            title: "Industry",
            visible: true,
          },
          {
            id: 5,
            title: "Avg Vol",
            icon: "rExclamation",
            tooltip: "Average Volume",
            tooltip1: "20 Day Average Volume.",
            visible: true,
          },
          {
            id: 6,
            title: "Optionable",
            visible: true,
          },
          {
            id: 7,
            title: "FV",
            icon: "rExclamation",
            tooltip: "Fair Value",
            tooltip1:
              "A ✔️ in this box indicates the stock is currently trading close or below its Fair Value price.",
            tooltip2:
              "A ❌ in this box indicates the stock is currently trading close or above its Fair Value price.",
            visible: true,
          },
          {
            id: 8,
            title: "ATP",
            icon: "rExclamation",
            tooltip: "Analytical Target Price",
            tooltip1:
              "A ✔️ in this box indicates the stock is currently trading below the short term analyst's taget price.",
            tooltip2:
              "A ❌ in this box indicates the stock is currently trading above the short term analyst's taget price.",
            visible: true,
          },
          {
            id: 9,
            title: "Chart",
            icon: "rExclamation",
            tooltip: "Charts",
            tooltip1: "3 Month Chart",
            setIcon: "setIcon",
            visible: true,
          },
        ];
      } else if (scannerType == "DividendStocks") {
        return [
          {
            id: 1,
            title: "Ticker",
            icon: "rExclamation",
            tooltip: "Stock's Ticker",
            tooltip1:
              "Stock's Ticker Symbol, Company Name, Current Price, Change, Earning Date.",
            visible: true,
          },

          {
            id: 3,
            title: "Rating",
            icon: "rExclamation",
            tooltip: "Stock Rating",
            tooltip1: "Stock's Rating from its Fundamental Analysis Score.",
            visible: true,
          },
          {
            id: 4,
            title: "Avg Vol",
            icon: "rExclamation",
            tooltip: "Average Volume",
            tooltip1: "20 Day Average Volume.",
            visible: true,
          },
          {
            id: 5,
            title: "Ind",
            icon: "rExclamation",
            tooltip: "Indicated",
            tooltip1:
              "This is the total dividend dollar abount paid out, per share, on a yearly basis. A dividend paid out quartly would be 1/4 of this amount.",
            visible: true,
          },
          {
            id: 6,
            title: "Yield",
            icon: "rExclamation",
            tooltip: "Yield",
            tooltip1:
              "The rate of return base on the current Indicated annual dividend / by the current stock price.",
            visible: true,
          },
          {
            id: 7,
            title: "Growth",
            icon: "rExclamation",
            tooltip: "Long tern (3-5) Year Dividend Growth",
            tooltip1:
              "The total historical yield growth rate over a 3 to 5 year period. Any positive number would indicate dividend growth.",
            visible: true,
          },
          {
            id: 8,
            title: "FV",
            icon: "rExclamation",
            tooltip: "Fair Value",
            tooltip1:
              "A ✔️ in this box indicates the stock is currently trading close or below its Fair Value price.",
            tooltip2:
              "A ❌ in this box indicates the stock is currently trading close or above its Fair Value price.",
            visible: true,
          },
          {
            id: 9,
            title: "ATP",
            icon: "rExclamation",
            tooltip: "Analytical Target Price",
            tooltip1:
              "A ✔️ in this box indicates the stock is currently trading below the short term analyst's taget price.",
            tooltip2:
              "A ❌ in this box indicates the stock is currently trading above the short term analyst's taget price.",
            visible: true,
          },
          {
            id: 9,
            title: "Chart",
            icon: "rExclamation",
            tooltip: "Charts",
            tooltip1: "3 Month Chart",
            setIcon: "setIcon",
            visible: true,
          },
        ];
      } else if (
        scannerType == "CurrentTrend" ||
        scannerType == "OverValuedStocks" ||
        scannerType == "UnderValuedStocks" ||
        scannerType == "StellarFundamental"
      ) {
        return [
          {
            id: 1,
            title: "Ticker",
            icon: "rExclamation",
            tooltip: "Stock's Ticker",
            tooltip1:
              "Stock's Ticker Symbol, Company Name, Current Price, Change, Earning Date.",
            visible: true,
          },

          {
            id: 3,
            title: "Sector",
            visible: true,
          },
          {
            id: 4,
            title: "Avg Vol",
            icon: "rExclamation",
            tooltip: "Average Volume",
            tooltip1: "20 Day Average Volume.",
            visible: true,
          },
          {
            id: 5,
            title: "Optionable",
            visible: true,
          },
          {
            id: 6,
            title: "Rating",
            icon: "rExclamation",
            tooltip: "Stock Rating",
            tooltip1: "Stock's Rating from its Fundamental Analysis Score.",
            visible: true,
          },
          {
            id: 9,
            title: "Chart",
            icon: "rExclamation",
            tooltip: "Charts",
            tooltip1: "3 Month Chart",
            setIcon: "setIcon",
            visible: true,
          },
        ];
      } else if (scannerType == "EarningCalendar") {
        return [
          {
            id: 1,
            title: "Ticker",
            icon: "rExclamation",
            tooltip: "Stock's Ticker",
            tooltip1:
              "Stock's Ticker Symbol, Company Name, Current Price, Change, Earning Date.",
            visible: true,
          },

          {
            id: 5,
            title: "Avg Vol",
            icon: "rExclamation",
            tooltip: "Average Volume",
            tooltip1: "20 Day Average Volume.",
            visible: true,
          },

          {
            id: 6,
            title: "EPS Surprise (Last Qtr)",

            visible: true,
          },
          {
            id: 7,
            title: "EPS Surprise (2 Qtr before)",
            visible: true,
          },
          {
            id: 4,
            title: "Rating",
            icon: "rExclamation",
            tooltip: "Stock Rating",
            tooltip1: "Stock's Rating from its Fundamental Analysis Score.",
            visible: true,
          },
          {
            id: 9,
            title: "Chart",
            icon: "rExclamation",
            tooltip: "Charts",
            tooltip1: "3 Month Chart",
            setIcon: "setIcon",
            visible: true,
          },
        ];
      } else if (
        scannerType == "EliteCoveredCalls" ||
        scannerType == "CoveredCallsCollars"
      ) {
        return [
          {
            id: 1,
            title: "Ticker",
            icon: "rExclamation",
            tooltip: "Stock's Ticker",
            tooltip1:
              "Stock's Ticker Symbol, Company Name, Current Price, Change, Earning Date.",
            visible: true,
          },
          {
            id: 3,
            title: "Dividend",
            visible: true,
          },
          {
            id: 4,
            title: "Rating",
            icon: "rExclamation",
            tooltip: "Stock Rating",
            tooltip1: "Stock's Rating from its Fundamental Analysis Score.",
            visible: true,
          },
          {
            id: 5,
            title: "FV",
            icon: "rExclamation",
            tooltip: "Fair Value",
            tooltip1:
              "A ✔️ in this box indicates the stock is currently trading close or below its Fair Value price.",
            tooltip2:
              "A ❌ in this box indicates the stock is currently trading close or above its Fair Value price.",
            visible: true,
          },
          {
            id: 6,
            title: "Trade",
            visible: true,
          },
          {
            id: 7,
            title: "Return%",
            visible: true,
          },
          {
            id: 9,
            title: "Chart",
            icon: "rExclamation",
            tooltip: "Charts",
            tooltip1: "3 Month Chart",
            setIcon: "setIcon",
            visible: true,
          },
        ];
      } else if (
        scannerType == "StockUnderTwenty" ||
        scannerType == "ThreeGreenArrows" ||
        scannerType == "ThreeRedArrows" ||
        scannerType == "UpsideBreakout" ||
        scannerType == "Downsidebreakout"
      ) {
        return [
          {
            id: 1,
            title: "Ticker",
            icon: "rExclamation",
            tooltip: "Stock's Ticker",
            tooltip1:
              "Stock's Ticker Symbol, Company Name, Current Price, Change, Earning Date.",
            visible: true,
          },

          {
            id: 3,
            title: "Sector",
            visible: true,
          },
          {
            id: 4,
            title: "Rating",
            icon: "rExclamation",
            tooltip: "Stock Rating",
            tooltip1: "Stock's Rating from its Fundamental Analysis Score.",
            visible: true,
          },
          {
            id: 5,
            title: "Avg Vol",
            icon: "rExclamation",
            tooltip: "Average Volume",
            tooltip1: "20 Day Average Volume.",
            visible: true,
          },
          {
            id: 6,
            title: "FV",
            icon: "rExclamation",
            tooltip: "Fair Value",
            tooltip1:
              "A ✔️ in this box indicates the stock is currently trading close or below its Fair Value price.",
            tooltip2:
              "A ❌ in this box indicates the stock is currently trading close or above its Fair Value price.",
            visible: true,
          },
          {
            id: 7,
            title: "ATP",
            icon: "rExclamation",
            tooltip: "Analytical Target Price",
            tooltip1:
              "A ✔️ in this box indicates the stock is currently trading below the short term analyst's taget price.",
            tooltip2:
              "A ❌ in this box indicates the stock is currently trading above the short term analyst's taget price.",
            visible: true,
          },
          {
            id: 9,
            title: "Chart",
            icon: "rExclamation",
            tooltip: "Charts",
            tooltip1: "3 Month Chart",
            setIcon: "setIcon",
            visible: true,
          },
        ];
      } else if (scannerType == "CanadianStocks") {
        return [
          {
            id: 1,
            title: "Ticker",
            icon: "rExclamation",
            tooltip: "Stock's Ticker",
            tooltip1:
              "Stock's Ticker Symbol, Company Name, Current Price, Change, Earning Date.",
            visible: true,
          },

          {
            id: 3,
            title: "CA Ticker",
            icon: "rExclamation",
            tooltip: "Canadian Ticker",
            tooltip1:
              "This is the Canadian Exchage Ticker Symbol and the Industry Name.",
            visible: true,
          },
          {
            id: 4,
            title: "Rating",
            icon: "rExclamation",
            tooltip: "Stock Rating",
            tooltip1: "Stock's Rating from its Fundamental Analysis Score.",
            visible: true,
          },
          {
            id: 5,
            title: "Avg Vol",
            icon: "rExclamation",
            tooltip: "Average Volume",
            tooltip1: "20 Day Average Volume.",
            visible: true,
          },
          {
            id: 6,
            title: "Optionable",
            visible: true,
          },
          {
            id: 7,
            title: "FV",
            icon: "rExclamation",
            tooltip: "Fair Value",
            tooltip1:
              "A ✔️ in this box indicates the stock is currently trading close or below its Fair Value price.",
            tooltip2:
              "A ❌ in this box indicates the stock is currently trading close or above its Fair Value price.",
            visible: true,
          },
          {
            id: 8,
            title: "ATP",
            icon: "rExclamation",
            tooltip: "Analytical Target Price",
            tooltip1:
              "A ✔️ in this box indicates the stock is currently trading below the short term analyst's taget price.",
            tooltip2:
              "A ❌ in this box indicates the stock is currently trading above the short term analyst's taget price.",
            visible: true,
          },
          {
            id: 9,
            title: "Chart",
            icon: "rExclamation",
            tooltip: "Charts",
            tooltip1: "3 Month Chart",
            setIcon: "setIcon",
            visible: true,
          },
        ];
      } else if (
        scannerType == "EquityETFs" ||
        scannerType == "IncomeFundETFs" ||
        scannerType == "IntlEquityETFs" ||
        scannerType == "ReitETFs"
      ) {
        return [
          {
            id: 1,
            title: "Ticker",
            icon: "rExclamation",
            tooltip: "Stock's Ticker",
            tooltip1:
              "Stock's Ticker Symbol, Company Name, Current Price, Change, Earning Date.",
            visible: true,
          },

          {
            id: 5,
            title: "Avg Vol",
            icon: "rExclamation",
            tooltip: "Average Volume",
            tooltip1: "20 Day Average Volume.",
            visible: true,
          },
          {
            id: 4,
            title: "Avg Dividend",
            visible: true,
          },
          {
            id: 9,
            title: "Chart",
            icon: "rExclamation",
            tooltip: "Charts",
            tooltip1: "3 Month Chart",
            setIcon: "setIcon",
            visible: true,
          },
        ];
      } else if (scannerType == "PopularETFs") {
        return [
          {
            id: 1,
            title: "Ticker",
            tooltip: "Stock's Ticker",
            tooltip1:
              "Stock's Ticker Symbol, Company Name, Current Price, Change, Earning Date.",
            visible: true,
          },

          {
            id: 5,
            title: "Avg Vol",
            icon: "rExclamation",
            tooltip: "Average Volume",
            tooltip1: "20 Day Average Volume.",
            visible: true,
          },
          {
            id: 4,
            title: "Avg Dividend",
            visible: true,
          },
          {
            id: 9,
            title: "Chart",
            tooltip: "Charts",
            tooltip1: "3 Month Chart",
            setIcon: "setIcon",
            visible: true,
          },
        ];
      }
    },
    transformcardData(apiData) {
      // Transform the data array
      return apiData.data.result[0].data.map((item) => ({
        card: {
          ticker: item?.ticker || "N/A",
          shareIcon: "share",
          shareIconbtn: "shareIconbtn",
          bName: item?.company || "Unknown Company",
          price: item?.currentprice ? item.currentprice.toFixed(2) : "N/A",
          currentprice: item?.currentprice
            ? item.currentprice.toFixed(2)
            : "N/A",
          change: "+0.17", // Example: Replace with actual change logic if needed
          cpercent: "(+1.82%)", // Example: Replace with actual percentage logic
          updateTime: "Last updated Yesterday", // Example: Replace with actual date logic
          favIcon: "favIcon",
          removeBtn: "Remove from watchlist",
          cname: "COMPANY",
          name: item?.company || "Unknown Company",
          avgVolName: "AVG VOL",
          avgVol: item?.avgVol ? item.avgVol.toLocaleString() : "N/A",
          analPriceName: "ANALYSTS AVG TARGET PRICE",
          analPrice: item?.atp ? item.atp.toFixed(2) : "N/A",
          nextearnDateName: "NEXT EARNING DATE",
          nextearnDate: new Date(item?.date).toLocaleDateString() || "N/A",
          sname: "Sector",
          sector: item?.sector || "Unknown Sector",
          fairvalName: "FAIR VALUE",
          grcheckbtn: "grcheckbtn",
          fairval: item?.fv ? item.fv.toFixed(2) : "N/A",
          numOfAnalName: "NUMBER OF ANALYSTS",
          numOfAnalists: item?.numOfAnalists, // Example: Add real value if available
          stockRatName: "STOCK RATING",
          srIcon: item?.rating,
          sRating: item?.rating || "N/A",
          rExclamation: "rExclamation",
          darrow: "darrow",
          showMoreBtn: "Show More",
        },
      }));
    },

    handleDocumentClick(event) {
      const box = this.$refs.box;
      if (box && !box.contains(event.target)) {
        // Check if `box` exists before accessing `.contains`
        this.isEnter = false;
        console.log("box is before", this.isEnter);
      } else {
        this.isEnter = true;
        console.log("box is after", this.isEnter);
      }
    },

    transformData(apiData, scannerType) {
      //   console.log("hello on", scannerType);
      this.totalResults = apiData?.data?.result[0]?.totalResults;
      if (!apiData?.data?.result[0]?.data) return [];
      switch (scannerType) {
        case "EarningCalendar":
          return apiData.data.result[0].data.map((item) => ({
            ticker: {
              id: 1,
              title: item?.ticker,
              shareIcon: "share",
              desc: item?.company,
              calenderIcon: "calender",
              currentprice:
                item?.currentprice !== 0 && item?.currentprice !== null
                  ? item?.currentprice.toFixed(2)
                  : "N/A",
              change: item?.change ? item?.change : 1.17,
              dArrow: "dArrow1",
              tArrow: "tArrow",
              date: item?.date
                ? new Date(item.date).toLocaleDateString()
                : "N/A",
              visible: true,
            },
            avgVol: {
              id: 5,
              avgVl: item?.avgVol.toLocaleString(),
              unit: this.formatLargeNumber(item?.avgVol).value,
              color: this.formatLargeNumber(item?.avgVol).color,
              visible: true,
            },
            epsSurpriceqtr: {
              id: 4,
              checkIcon: "checkbtn",
              epsSurpriceqtr: item?.epssurprise ?? -2.06,
              visible: true,
            },
            epsSurpriceLastqtr: {
              id: 5,
              checkIcon: "checkbtn",
              epsSurpriceLastqtr: item.epssurpriseqtr ?? 13.16,
              visible: true,
            },
            rating: {
              id: 6,
              rating: item?.rating,
              visible: true,
            },
          }));

        case "EliteCoveredCalls":
        case "CoveredCallsCollars":
          return apiData.data.result[0].data.map((item) => ({
            ticker: {
              id: 1,
              title: item?.ticker,
              shareIcon: "share",
              desc: item?.company,
              calenderIcon: "calender",
              currentprice: item?.currentprice ?? "N/A",
              change: item?.change ?? 1.17,
              dArrow: "dArrow1",
              tArrow: "tArrow",
              date: item?.date,
              visible: true,
            },
            dividend: {
              id: 3,
              dividend: item?.dividend,
              dividendInPercent: item?.dividendInPercent,
              visible: true,
            },
            rating: {
              id: 7,
              rating: item?.rating,
              visible: true,
            },
            fv: {
              id: 8,
              checkIcon: "checkbtn",
              fv: item?.fv,
              currentprice: item?.currentPrice,
              visible: true,
            },
            trade: {
              id: 6,
              sellDate: item?.date,
              call1: item?.call1,
              call2: item?.call2,
              putPrice: item?.put,
              visible: true,
            },
            maxAndMinRateOfReturn: {
              id: 7,
              expMaxDate: item?.expDateFormattedMax,
              maxratereturn: item?.maxratereturn,
              visible: true,
              date: "2025-03-25T00:00:00Z",
              expMinDate: item?.expDateFormattedMin,
              minratereturn: item?.minratereturn,
            },
          }));

        case "StockUnderTwenty":
        case "ThreeGreenArrows":
        case "ThreeRedArrows":
        case "UpsideBreakout":
        case "Downsidebreakout":
          return apiData.data.result[0].data.map((item) => ({
            ticker: {
              id: 1,
              title: item?.ticker,
              shareIcon: "share",
              desc: item?.company,
              calenderIcon: "calender",
              currentprice:
                item?.currentprice !== 0 && item?.currentprice !== null
                  ? item?.currentprice
                  : "N/A",
              change: item?.change ?? 1.17,
              dArrow: "dArrow1",
              tArrow: "tArrow",
              date: item?.date
                ? new Date(item.date).toLocaleDateString()
                : "N/A",
              visible: true,
            },
            sector: {
              id: 3,
              sector: item?.sector,
              visible: true,
            },
            rating: {
              id: 3,
              rating: item?.rating,
              visible: true,
            },
            avgVol: {
              id: 5,
              avgVl: item?.avgVol.toLocaleString(),
              unit: this.formatLargeNumber(item?.avgVol).value,
              color: this.formatLargeNumber(item?.avgVol).color,
              visible: true,
            },
            fv: {
              id: 8,
              checkIcon: "checkbtn",
              fv: item?.fv,
              currentprice: item?.currentPrice,
              visible: true,
            },
            atp: {
              id: 9,
              checkIcon: "checkbtn",
              atp: item?.atp,
              currentprice: item?.currentPrice,
              visible: true,
            },
          }));

        case "CanadianStocks":
          return apiData.data.result[0].data.map((item) => ({
            ticker: {
              id: 1,
              title: item?.ticker,
              shareIcon: "share",
              desc: item?.company,
              calenderIcon: "calender",
              currentprice: item?.currentprice ?? "N/A",
              change: item?.change ?? 1.17,
              dArrow: "dArrow1",
              tArrow: "tArrow",
              date: item?.date,
              visible: true,
            },
            caTicker: {
              id: 3,
              caTicker: item?.caTicker,
              shareIcon: "share",
              desc: this.capitalizeWords(item?.industry),
              visible: true,
            },
            rating: {
              id: 3,
              rating: item?.rating,
              visible: true,
            },
            avgVol: {
              id: 5,
              avgVl: item?.avgVol.toLocaleString(),
              unit: this.formatLargeNumber(item?.avgVol).value,
              color: this.formatLargeNumber(item?.avgVol).color,
              visible: true,
            },
            optionable: {
              id: 6,
              optionable: item?.optionable,
              visible: true,
            },
            fv: {
              id: 8,
              checkIcon: "checkbtn",
              fv: item?.fv,
              currentprice: item?.currentPrice,
              visible: true,
            },
            atp: {
              id: 9,
              checkIcon: "checkbtn",
              atp: item?.atp,
              currentprice: item?.currentPrice,
              visible: true,
            },
          }));

        case "GoodStocks":
        case "BadStocks":
        case "AlmostGoodStocks":
          return apiData.data.result[0].data.map((item) => ({
            ticker: {
              id: 1,
              title: item?.ticker,
              shareIcon: "share",
              desc: item?.company,
              calenderIcon: "calender",
              currentprice:
                item?.currentprice !== 0 && item?.currentprice !== null
                  ? item?.currentprice.toFixed(2)
                  : "N/A",
              change: item?.change ? item?.change : 1.17,
              dArrow: "dArrow1",
              tArrow: "tArrow",
              date: item?.date
                ? new Date(item.date).toLocaleDateString()
                : "N/A",
              // visible: true,
            },

            sector: {
              id: 3,
              sector: item?.sector,
              visible: true,
            },
            industry: {
              // TODO: To be checked
              id: 4,
              industry: this.capitalizeWords(item?.industry),
              visible: true,
            },
            avgVol: {
              id: 5,
              avgVl: item?.avgVol.toLocaleString(), // Format the average volume with commas
              unit: this.formatLargeNumber(item?.avgVol).value, // Use only the formatted value
              color: this.formatLargeNumber(item?.avgVol).color, // Extract the color
              visible: true,
            },
            optionable: {
              id: 6,
              optionable: item?.optionable,
              visible: true,
            },
            fv: {
              id: 8,
              checkIcon: "checkbtn",
              fv: item?.fv,
              currentprice: item?.currentPrice,
              visible: true,
            },
            atp: {
              id: 9,
              checkIcon: "checkbtn",
              atp: item.atp,
              currentprice: item?.currentPrice,
              visible: true,
            },
          }));

        case "CurrentTrend":
        case "OverValuedStocks":
        case "UnderValuedStocks":
        case "StellarFundamental":
          return apiData.data.result[0].data.map((item) => ({
            ticker: {
              id: 1,
              title: item?.ticker,
              shareIcon: "share",
              desc: item?.company,
              calenderIcon: "calender",
              currentprice:
                item?.currentprice !== 0 && item?.currentprice !== null
                  ? item?.currentprice
                  : "N/A",
              change: item?.change ? item?.change : 1.17,
              dArrow: "dArrow1",
              tArrow: "tArrow",
              date: item?.date
                ? new Date(item.date).toLocaleDateString()
                : "N/A",
              visible: true,
            },

            sector: {
              id: 3,
              sector: item?.sector,
              visible: true,
            },
            avgVol: {
              id: 5,
              avgVl: item?.avgVol.toLocaleString(), // Format the average volume with commas
              unit: this.formatLargeNumber(item?.avgVol).value, // Use only the formatted value
              color: this.formatLargeNumber(item?.avgVol).color, // Extract the color
              visible: true,
            },
            optionable: {
              id: 6,
              optionable: item?.optionable,
              visible: true,
            },
            rating: {
              id: 3,
              rating: item?.rating,
              visible: true,
            },
          }));

        case "DividendStocks":
          return apiData.data.result[0].data.map((item) => ({
            ticker: {
              id: 1,
              title: item?.ticker,
              shareIcon: "share",
              desc: item?.company,
              calenderIcon: "calender",
              currentprice:
                item?.currentprice !== 0 && item?.currentprice !== null
                  ? item?.currentprice
                  : "N/A",
              change: item?.change ? item?.change : 1.17,
              dArrow: "dArrow1",
              tArrow: "tArrow",
              date: item?.date
                ? new Date(item.date).toLocaleDateString()
                : "N/A",
              visible: true,
            },

            rating: {
              id: 7,
              rating: item?.rating,
              visible: true,
            },
            avgVol: {
              id: 5,
              avgVl: item?.avgVol.toLocaleString(), // Format the average volume with commas
              unit: this.formatLargeNumber(item?.avgVol).value, // Use only the formatted value
              color: this.formatLargeNumber(item?.avgVol).color, // Extract the color
              visible: true,
            },
            ind: {
              id: 5,
              indicate: item?.ind ? item?.ind : "0.60",
              visible: true,
            },
            yield: {
              id: 6,
              yield: item?.yield ? item?.yield : "23.62",
              visible: true,
            },
            growth: {
              id: 7,
              growth: item?.growth ? item?.growth : "13.21",
              visible: true,
            },
            fv: {
              id: 8,
              checkIcon: "checkbtn",
              fv: item?.fv,
              currentprice: item?.currentPrice,
              visible: true,
            },
            atp: {
              id: 9,
              checkIcon: "checkbtn",
              atp: item.atp,
              currentprice: item?.currentPrice,
              visible: true,
            },
          }));

        case "EquityETFs":
        case "IncomeFundETFs":
        case "IntlEquityETFs":
        case "ReitETFs":
          return apiData.data.result[0].data.map((item) => ({
            ticker: {
              id: 1,
              title: item?.ticker,
              shareIcon: "share",
              desc: item?.company,
              calenderIcon: "calender",
              currentprice:
                item?.currentprice !== 0 && item?.currentprice !== null
                  ? item?.currentprice
                  : "N/A",
              change: item?.change ? item?.change : 1.17,
              dArrow: "dArrow1",
              tArrow: "tArrow",
              date: item?.date
                ? new Date(item.nextEpsReportDate).toLocaleDateString()
                : "N/A",
              visible: true,
            },

            avgVol: {
              id: 5,
              avgVl: item?.avgVol.toLocaleString(), // Format the average volume with commas
              unit: this.formatLargeNumber(item?.avgVol).value, // Use only the formatted value
              color: this.formatLargeNumber(item?.avgVol).color, // Extract the color
              visible: true,
            },
            avgDividend: {
              id: 4,
              avgDividend: item?.divPercent,
              visible: true,
            },
          }));

        // Add other cases as needed

        default:
          return [];
      }
    },
  },

  computed: {
    // page count functions
    totalPages() {
      return Math.ceil(this.totalResults / this.itemsPerPage);
    },
    showLeftEllipsis() {
      return this.currentPage > 3; // Show ellipsis if we're beyond page 3
    },
    showRightEllipsis() {
      return this.currentPage < this.totalPages - 2; // Show ellipsis if we're before the last 2 pages
    },
    middleButtons() {
      const buttons = [];
      const maxButtons = 3; // Number of buttons between ellipses
      const totalPages = Math.ceil(this.totalResults / this.itemsPerPage);

      if (this.currentPage <= 3) {
        // If the user is on one of the first three pages
        for (let i = 2; i <= Math.min(totalPages - 1, maxButtons + 1); i++) {
          buttons.push(i);
        }
      } else if (this.currentPage >= totalPages - 2) {
        // If the user is on one of the last three pages
        for (let i = totalPages - maxButtons; i < totalPages; i++) {
          if (i > 1) buttons.push(i); // Avoid duplicating the first page button
        }
      } else {
        // If the user is in the middle
        buttons.push(
          this.currentPage - 1,
          this.currentPage,
          this.currentPage + 1
        );
      }

      return buttons;
    },
  },

  created() {
    // Listen for the "isActiveScan" event
  },

  async mounted() {
    ChartEvent.$on("isActiveScan", (name, title) => {
      console.log("Received isActiveScan event:1", name, title);
      this.isActiveScanner = name;
      this.currentPage = 1;
      this.isActive = title;
      this.fetchData(this.currentPage);
      console.log(
        "Received isActiveScan event:2",
        this.isActiveScanner,
        this.isActive
      );
    });

    // Load the first page of data when the component mounts
    this.isLoading = true;
    try {
      const initialData = await this.fetchData(this.currentPage);
      if (initialData.length < this.itemsPerPage) {
        this.hasMoreData = false;
      }
      this.allData = [...initialData];
      this.isLoading = false;
    } catch (error) {
      console.error("Error fetching initial data:", error);
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }

    this.updateTime();
    this.interval = setInterval(this.updateTime, 1000);
  },
  beforeDestroy() {
    // Remove the listener to prevent memory leaks
    ChartEvent.$off("isActiveScan");
    clearInterval(this.interval);
  },
};
</script>

<style scoped></style>
