<template>
  <div class="w-full h-full -bg-slate-300">
    <div
      class="w-full h-full -bg-slate-300 overflow-y-auto border-l border-r invisible-scroolbar rounded"
    >
      <table class="min-w-full w-full -!bg-black relative leading-normal">
        <thead
          :style="{ backgroundColor: backgroundColor }"
          class="-bg-[#037950] w-full -bg-slate-500 sticky top-0 mrrounded-tl-xl mrrounded-tr-xl z-[999]"
          style="z-index: 50 !important"
        >
          <tr class="-bg-black flex w-full -bg-slate-300 -justify-between">
            <th
              v-for="(column, index) in localColumnsData"
              :key="index"
              v-show="column.visible"
              :class="[
                column?.title == 'Ticker' ? '!w-[15%]' : '',
                column?.title == 'Chart' ? 'w-[8%] flex-auto' : '',
              ]"
              class="--px-5 relative text-[14px] flex-1 w-[13.4%] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex justify-between items-center text-left text-white -uppercase -bg-white"
            >
              <TooltipVue
                :tooltip="column?.tooltip"
                :tooltip1="column?.tooltip1"
                :tooltip2="column?.tooltip2"
                placement="right"
              >
                <div
                  :class="
                    column?.setIcon == 'setIcon'
                      ? 'justify-between flex -bg-slate-500 -w-full px-0'
                      : ''
                  "
                  class="flex items-center gap-2 px-3"
                >
                  {{ column?.title }}
                  <span
                    v-if="column?.icon"
                    v-html="getSvg(column?.icon)"
                  ></span>
                </div>
              </TooltipVue>
              <div
                v-if="column?.title == 'Chart' ? '' : 'hidden'"
                class="w-[0.2px] h-[38px] bg-black"
              />
            </th>
            <th
              class="absolute -bg-slate-300 justify-end flex h-full w-[250px] right-0"
            >
              <div class="relative flex items-center">
                <button @click="toggleDropdown" class="mr-5 text-white">
                  <span v-html="getSvg('setIcon')"></span>
                </button>
                <!-- Dropdown is here -->
                <div
                  v-if="showDropdown"
                  class="w-[65vh] absolute top-[40px] right-0 border rounded-bl rounded-br shadow-xl overflow-hidden h-[55vh] absolute bg-white flex flex-col"
                >
                  <!-- v-if="!showDropdown" -->
                  <ColumnModal
                    @toggledropdown="toggleDropdown"
                    :columnsData="columnsData"
                  />
                  <!-- <div
                    v-for="(column, index) in columnsData"
                    :key="index"
                    @click.stop="toggleColumn(index)"
                    :class="
                      column?.title == 'Chart' || column?.title == 'Ticker'
                        ? 'pointer-events-none bg-[#f3f0f0]'
                        : ''
                    "
                    class="w-full h-full px-3 cursor-pointer border-t flex items-center gap-2 text-black hover:bg-[#E5F3EC]"
                  >
                    
                    <i
                      v-if="column.visible"
                      class="text-[#178f57]"
                      v-html="getSvg('checkBox')"
                    ></i>
                    <i
                      v-if="!column.visible"
                      class="text-[#178f57]"
                      v-html="getSvg('unCheckBox')"
                    ></i>
                    <span class="text-[14px] font-[500]">{{
                      column?.title
                    }}</span>
                  </div> -->
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="relative w-full min-w-full">
          <!-- CurrentTrend, OverValuedStocks, StellarFundamental, UnderValuedStocks 5/4-->
          <tr
            v-for="(row, rowIndex) in localRowsData"
            :key="rowIndex"
            class="-bg-black border flex h-27"
          >
            <!-- ticker -->
            <td
              scope="col"
              class="py-2 w-[15%] flex flex-1 justify-between items-center text-left text-black -uppercase"
            >
              <div class="flex flex-col gap-1 pl-3">
                <div
                  class="flex text-[15px] leading-[15px] items-center gap-2 tracking-[.5px]"
                  style="font-weight: 550"
                >
                  {{ row?.ticker?.title }}
                  <span
                    class="h-3 w-3"
                    v-html="getSvg(row?.ticker?.shareIcon)"
                  ></span>
                </div>
                <div class="flex items-center gap-1">
                  <span class="text-[12px] leading-[15px]"
                    >${{ row?.ticker?.currentprice }}</span
                  >
                  <div
                    :class="
                      row?.ticker?.change > 0
                        ? 'text-[#037950]'
                        : 'text-red-600'
                    "
                    class="flex !items-center gap-1"
                  >
                    <span
                      v-if="row?.ticker?.change > 0"
                      v-html="getSvg(row?.ticker?.tArrow)"
                    ></span>
                    <span
                      v-if="row?.ticker?.change < 0"
                      v-html="getSvg(row?.ticker?.dArrow1)"
                    ></span>
                    <span class="text-[12px] leading-[15px]">
                      {{ "(" + row?.ticker?.change + "%)" }}
                    </span>
                  </div>
                </div>
                <span
                  class="font-[400] leading-[15px] text-[13px] font-inter"
                  :class="
                    row?.ticker?.id == 1 ? 'text-[#8d8c8c]' : 'text-[#7A7A7A]'
                  "
                  >{{ row?.ticker?.desc }}</span
                >
                <div class="flex w-full items-center gap-1">
                  <span
                    class="text-[#808080] w-2.5 stroke-[#c0c3c6]"
                    v-html="getSvg('calenderMain')"
                  />
                  <span class="text-[11px]">
                    {{ formatedDate(row?.ticker?.date) }}
                  </span>
                </div>
              </div>

              <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
            </td>

            <!-- Earning Date -->
            <!-- <td
              v-if="row?.earningDate?.visible"
              scope="col"
              class="py-3 w-[12%] flex justify-between items-center text-left text-black -uppercase -bg-white"
            >
              <div
                class="flex items-center justify-center gap-2 px-3 w-full -bg-slate-200"
              >
                <span
                  class="text-[#b4b3b3]"
                  v-html="getSvg('calenderMain')"
                ></span>
                <div class="flex flex-col justify-center items-center">
                  <div
                    class="text-[#000000] flex gap-1 font-[400] leading-[15px] tracking-[0.5px] text-[14px] font-inter"
                  >
                    <span>{{
                      getDateOnly(row?.earningDate?.earningdate)
                    }}</span>
                    <span>{{
                      getMonthName(row?.earningDate?.earningdate)
                    }}</span>
                  </div>
                  <span
                    class="text-[#969595] font-[400] leading-[14px] tracking-[0.5px] text-[11px] -font-inter"
                    >{{ getYearOnly(row?.earningDate?.earningdate) }}</span
                  >
                </div>
              </div>
              <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
            </td> -->

            <!-- sector -->
            <td
              v-if="row?.sector?.visible"
              scope="col"
              class="py-3 w-[13.4%] flex flex-1 justify-between items-center text-left text-black -uppercase -bg-white"
            >
              <div class="flex flex-col gap-2 px-3">
                <span
                  class="text-[#1d1d1d] line-clamp-2 font-[400] leading-[18px] text-[12px] font-inter"
                  >{{ row?.sector?.sector }}</span
                >
              </div>
              <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
            </td>
            <!-- industry -->
            <td
              v-if="row?.industry?.visible"
              scope="col"
              class="py-3 w-[13.4%] flex flex-1 justify-between items-center text-left text-black -uppercase -bg-white"
            >
              <div class="flex flex-col gap-2 px-3">
                <span
                  class="text-[#1d1d1d] line-clamp-2 font-[400] leading-[18px] text-[12px] font-inter"
                  >{{ row?.industry?.industry }}</span
                >
              </div>
              <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
            </td>
            <!-- avg vol -->
            <td
              v-if="row?.avgVol?.visible"
              scope="col"
              class="py-3 w-[13.4%] flex flex-1 justify-end items-center !text-right text-black -uppercase -bg-white"
            >
              <div class="flex flex-col -bg-slate-300 gap-1.5 px-3">
                <span
                  class="text-[#1d1d1d] leading-[15px] font-[500] text-[14px]"
                  >{{ row?.avgVol?.avgVl }}</span
                >
                <span
                  :style="{ color: row?.avgVol?.color }"
                  class="text-[#8f8f8f] font-[400] leading-[13px] text-[14px]"
                  >{{ row?.avgVol?.unit }}</span
                >
              </div>
              <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
            </td>
            <!-- optionable -->
            <td
              v-if="row?.optionable?.visible"
              scope="col"
              class="py-3 w-[13.4%] flex-1 text-[14px] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex justify-between items-center text-left text-black -uppercase -bg-white"
            >
              <div class="flex mx-auto items-center gap-2 px-3 h-full">
                <span
                  class="h-10 w-16 pt-2"
                  v-if="row?.optionable?.optionable == 1"
                  v-html="getSvg('checkIcon')"
                ></span>
                <span
                  class="h-10 w-16 pt-2"
                  v-if="row?.optionable?.optionable == 0"
                  v-html="getSvg('crossIcon')"
                ></span>
              </div>
              <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
            </td>
            <!-- rating -->
            <td
              v-if="row?.rating?.visible"
              scope="col"
              class="py-3 w-[13.4%] flex-1 text-[14px] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex justify-between items-center text-left text-black -uppercase -bg-white"
            >
              <div
                class="flex mx-auto flex-col justify-center gap-2 -bg-black --px-3"
              >
                <span
                  class="text-[#7A7A7A] font-[400] leading-[15px] text-[12px] font-inter h-14 w-20"
                  v-html="getSvg(row?.rating?.rating)"
                ></span>
              </div>

              <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
            </td>
            <!-- chart -->
            <td
              style=""
              scope="col"
              class="py-3 text-[14px] w-[8%] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex flex-auto justify-center -bg-black items-center -text-left text-black -uppercase -bg-yellow-500"
            >
              <div class="flex w-full -bg-green-300 flex-col gap-2 px-3">
                <div
                  class="w-full flex-wrap -bg-slate-300 h-[70px] flex justify-center items-center"
                >
                  <!-- <AreaChart /> -->
                  <img
                    class="w-full h-full object-contain"
                    :src="`https://incometradercharts.com/fmgraphttransparent.ashx?h=80&w=360&bw=3&sr=true&s=${row?.ticker?.title}&int=true`"
                    alt=""
                  />
                </div>
              </div>
            </td>
          </tr>

          <div
            v-if="!isLoading && this.localRowsData.length < 1"
            class="flex justify-center items-center -bg-[#ECECEC] w-[95%] h-[660px]"
          >
            <h1 class="text-[20px]">No result found!</h1>
          </div>
          <div
            v-if="isLoading"
            class="flex bg-white/50 justify-center items-center -absolute fixed top-[150px] w-[82%] h-[660px]"
          >
            <span class="loader"></span>
          </div>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { getSvgByName } from "../../assets/svgs/CurrentUptrend.js";
import ColumnModal from "../../components/ColumnOptionModal/index.vue";
import TooltipVue from "../ToolTipVue/TooltipVue.vue";
import moment from "moment";
import ChartEvent from "@/eventBus.js";

export default {
  name: "GBAScanner",
  data() {
    return {
      showDropdown: false,
      isEnter: false,
      formattedTime: "",
      hiddenColumns: [],
      isCardView: false,
      isActiveScanner: "GoodStocks",
      isActive: "Good Stocks",
      allData: [],
      currentPage: 1,
      pageStart: 1,
      pageEnd: 50,
      itemsPerPage: 50,
      localColumnsData: JSON.parse(JSON.stringify(this.columnsData)), // Deep copy of columnsData
      localRowsData: JSON.parse(JSON.stringify(this.rowsData)), // Deep copy of rowsData
    };
  },
  components: {
    ColumnModal,
    TooltipVue,
  },

  props: {
    rowsData: {
      type: Array,
      required: true,
    },
    columnsData: {
      type: Array,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: "#F5F5F5",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatedDate(val) {
      console.log("val", val);
      return val === "N/A" ? "N/A" : moment(val).format("MM/DD/YYYY");
    },
    getSvg(name) {
      return getSvgByName(name);
    },

    getDateOnly(dateString) {
      const date = new Date(dateString);
      return isNaN(date) ? "N/A" : date.getDate(); // Returns only the day of the month (1-31)
    },

    getMonthName(dateString) {
      const date = new Date(dateString);
      return isNaN(date)
        ? "N/A"
        : date.toLocaleString("default", { month: "short" }); // Returns the full month name
    },

    getYearOnly(dateString) {
      const date = new Date(dateString);
      return isNaN(date) ? "N/A" : date.getFullYear(); // Returns only the year (e.g., 2025)
    },

    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
      console.log("toggleDropdown", this.rowsData);
    },
    toggleColumn(index) {
      this.columns[index].visible = !this.columns[index].visible;
      this.rowsData?.map((row) => {
        row[`column${index + 1}`].visible = this.columns[index].visible;
      });
    },
    // datalo() {
    //   this.allData = this.rowsData
    // },
  },
  computed: {
    //  this.datalo()
    // atalo() {
    //   console.log("this.rowsData", this.rowsData);
    //   this.allData = this.$props.rowsData
    //   return this.allData;
    // },
  },
  mounted() {
    ChartEvent.$on("toggleColumn", (index) => {
      // alert(index);

      // Toggle visibility for the column
      this.localColumnsData[index].visible =
        !this.localColumnsData[index].visible;

      // Toggle visibility for the corresponding row data
      this.localRowsData.forEach((row) => {
        console.log(row);

        const columnKeys = Object.keys(row); // Get all keys in the row object

        if (columnKeys[index]) {
          // Toggle visibility for the specific column
          row[columnKeys[index]].visible = !row[columnKeys[index]].visible;
          console.log(
            `Toggled visibility for column: ${row[columnKeys[index]].visible}`
          );
        } else {
          console.warn(`No column found at index ${index}`);
        }
      });

      // alert(this.localColumnsData[index].visible);
      // alert("hello");
    });
  },
  beforeDestroy() {
    ChartEvent.$off("toggleColumn"); // Use $off to clean up the event listener
  },
  watch: {
    columnsData: {
      handler(newVal) {
        this.localColumnsData = JSON.parse(JSON.stringify(newVal)); // Sync with prop changes
      },
      deep: true,
    },
    rowsData: {
      handler(newVal) {
        this.localRowsData = JSON.parse(JSON.stringify(newVal));
        console.log(this.localRowsData);
      },
      deep: true,
    },
  },
};
</script>

<style></style>
