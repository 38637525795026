<template>
  <div
    class="w-full h-full flex mrborder -mrborder-[#D1D1D1] overflow-hidden flex-col mrrounded-lg justify-between bg-white"
  >
    <!-- header  -->
    <div
      :style="{
        // backgroundColor: backgroundColor,
        mrborder: backgroundColor,
      }"
      :class="[
          `w-full min-h-[40px]  --font-inter flex flex-col justify-center items-start px-5 uppercase text-xs leading-[12.52px] tracking-[0.8px]`,
          `font-[300]`,
          `text-[#ffffff]`,
          `bg-[${backgroundColor}]`
      ]"
    >
      <span>{{ title }}</span>
    </div>

    <!-- search bar content -->
    <div
      v-if="title === 'Stock Quote & Stock Evaluator ®'"
      class="w-full h-full flex flex-col justify-center items-center gap-3 py-3 mrrounded-bl-lg mrrounded-br-lg mrborder mrborder-[#D1D1D1] --font-inter text-[16px] leading-[14.52px] tracking-[0.2] -bg-[#6D6D6D]"
    >
      <div
        @click="isEnter = !isEnter"
        ref="box"
        :class="[
          title === 'Stock Quote & Stock Evaluator ®'
            ? 'w-[90%] cursor-pointer'
            : 'w-[90%]',
          isSearchBoxFocused ? 'border-[#333333]' : 'border-[#161616]'
        ]"
        class="w-[93%] -rounded box px-2 h-[40px] items-center overflow-hidden border-[1px] border-[#D1D1D1] flex gap-2 justify-center font-[400] text-[16px] leading-[14.52px] tracking-[0.2] -bg-[#F6F6F6] text-[#6D6D6D]"
      >
        <span class="cursor-pointer" v-html="getSvg('search')"></span>
        <input
          class="w-[65%] -pr-2 py-2 text-[14px] --font-inter -font-[300] -bg-[#F6F6F6] focus:outline-none ring-0"
          :class="[
              isSearchBoxFocused ? `text-[#161616] ` : 'text-[#6D6D6D] ',
          ]"
          type="text"
          @focus="isSearchBoxFocused = true"
          @blur="isSearchBoxFocused = false"
          placeholder="Search ticker"
        />
        <!-- <span class="cursor-pointer" v-html="getSvg('enterkey')"></span> -->
        <div class="w-[50px] h-[70%] flex items-center justify-center border">
          <span class="text-[12px] text-[#aaaaaa]">Enter</span>
        </div>
      </div>
      <div
        class="w-[90%] hidden cursor-pointer mrrounded-xl pl-6 h-[50px] mrborder-[2px] mrborder-[#037950] items-center overflow-hidden -flex gap-3 justify-center font-[400] text-[16px] leading-[14.52px] tracking-[0.2] bg-[#ECFDF4] hover:bg-[#d1fae2] text-[#6D6D6D]"
      >
        <span class="cursor-pointer" v-html="getSvg('symbollookup')"></span>
        <span
          class="w-[85%] font-[600] text-[18px] leading-[21px] tracking-[2.2%] pr-1 py-2 text-[#037950]"
          >Symbol Lookup</span
        >
      </div>
    </div>

    <!-- btn content -->
    <div
      v-else
      class="w-full h-full flex flex-col py-2 justify-center items-center mrborder mrborder-[#D1D1D1] mrrounded-bl-lg mrrounded-br-lg gap-3 font-[400] text-[16px] leading-[14.52px] tracking-[0.2] -bg-[#6D6D6D]"
    >
      <div
        v-for="(item, index) in btnData"
        @click="isActive = item?.title"
        :key="index"
        :class="
          item?.title === isActive
            ? 'mrborder mrborder-[#037950] !bg-[#A8D6C14D] !text-[#037950]'
            : 'mrborder mrborder-transparent !text-[#6D6D6D]'
        "
        class="w-[93%] cursor-pointer mrrounded-xl px-4 h-[40px] hover:bg-[#A8D6C14D] -mrborder -mrborder-[#037950] items-center overflow-hidden flex gap-3 justify-center bg-[#ffffff]"
      >
        <span class="cursor-pointer" v-html="getSvg(item?.icon)"></span>
        <span
          class="w-[85%] leading-[21px] tracking-[0.5px] pr-1 py-2 text-[#555F6D] --font-inter text-sm font-extralight"
          >{{ item?.title }}</span
        >
        <span
          v-if="item?.newTitle"
          class="text-[#D8A60F] bg-[#FFB3002E] -font-[300] rounded-2xl px-3 py-[4.5px]"
          >{{ item?.newTitle }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getSvgByName } from "../../assets/svgs/Leftbar.js";

export default {
  name: "NavBar",

  data() {
    return {
      isEnter: false,
      isActive: "Watchlist",
      isSearchBoxFocused: false
    };
  },

  props: {
    title: String,
    btnData: Array,
    backgroundColor: String,
  },

  mounted() {
    this.$nextTick(() => {
      document.addEventListener("click", this.handleDocumentClick);
    });
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleDocumentClick);
  },
  methods: {
    getSvg(name) {
      return getSvgByName(name);
    },
    handleDocumentClick(event) {
      const box = this.$refs.box;
      if (box && !box.contains(event.target)) {
        // Check if `box` exists before accessing `.contains`
        this.isEnter = false;
        // console.log("box is clicked1");
      } else {
        this.isEnter = true;
        // console.log("box is clicked2");
      }
    },
  },
};
</script>

<style>
input::-webkit-input-placeholder {
  font-size: 15px;
  font: 800 !important;
  color: #6d6d6d !important;
  font-family: inter, sans-serif;
}
input::-webkit-input-placeholder {
  font-size: 15px;
  font: 900 !important;
  color: #6d6d6d !important;
}

/* Global scrollbar styling for WebKit browsers */
::-webkit-scrollbar {
  width: 5px; /* Width of vertical scrollbar */
  height: 12px; /* Height of horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar handle */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of handle when hovered */
}
</style>
