<template>
  <div class="w-full h-full -bg-slate-300">
    <div
      class="w-full h-full -bg-slate-300 overflow-y-auto border-l border-r invisible-scroolbar rounded"
    >
      <table class="min-w-full w-full -!bg-black relative leading-normal">
        <thead
          :style="{ backgroundColor: backgroundColor }"
          class="-bg-[#037950] w-full -bg-slate-500 sticky top-0 mrrounded-tl-xl mrrounded-tr-xl z-[999]"
          style="z-index: 50 !important"
        >
          <tr class="-bg-black flex w-full -bg-slate-300 -justify-between">
            <th
              v-for="(column, index) in localColumnsData"
              :key="index"
              v-show="column.visible"
              :class="[
                column?.title == 'Ticker' ? '!w-[12%]' : '',
                column?.title == 'Return%' ? 'w-[12%]' : '',
                column?.title == 'Trade' ? '!w-[15%] -bg-green-600' : '',
                column?.title == 'Chart' ? '!w-[8%] flex-auto' : '',
              ]"
              class="--px-5 relative text-[14px] w-[11%] flex-1 font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex justify-between items-center text-left text-white -uppercase -bg-green-500"
            >
              <TooltipVue
                :tooltip="column?.tooltip"
                :tooltip1="column?.tooltip1"
                :tooltip2="column?.tooltip2"
                placement="right"
              >
                <div
                  :class="
                    column?.setIcon == 'setIcon'
                      ? 'justify-between flex -bg-slate-500 -w-full px-0'
                      : ''
                  "
                  class="flex items-center gap-2 px-3"
                >
                  {{ column?.title }}
                  <span
                    v-if="column?.icon"
                    v-html="getSvg(column?.icon)"
                  ></span>
                </div>
              </TooltipVue>
              <div
                v-if="column?.title == 'Chart' ? '' : 'hidden'"
                class="w-[0.2px] h-[38px] bg-black"
              />
            </th>
            <th
              class="absolute -bg-slate-300 justify-end flex h-full w-[250px] right-0"
            >
              <div class="relative flex items-center">
                <button @click="toggleDropdown" class="mr-5 text-white">
                  <span v-html="getSvg('setIcon')"></span>
                </button>
                <!-- Dropdown is here -->
                <div
                  v-if="showDropdown"
                  class="w-[65vh] absolute top-[40px] right-0 border rounded-bl rounded-br shadow-xl overflow-hidden h-[55vh] absolute bg-white flex flex-col"
                >
                  <!-- v-if="!showDropdown" -->
                  <ColumnModal
                    @toggledropdown="toggleDropdown"
                    :columnsData="columnsData"
                  />
                  <!-- <div
                    v-for="(column, index) in columnsData"
                    :key="index"
                    @click.stop="toggleColumn(index)"
                    :class="
                      column?.title == 'Chart' || column?.title == 'Ticker'
                        ? 'pointer-events-none bg-[#f3f0f0]'
                        : ''
                    "
                    class="w-full h-full px-3 cursor-pointer border-t flex items-center gap-2 text-black hover:bg-[#E5F3EC]"
                  >
                    
                    <i
                      v-if="column.visible"
                      class="text-[#178f57]"
                      v-html="getSvg('checkBox')"
                    ></i>
                    <i
                      v-if="!column.visible"
                      class="text-[#178f57]"
                      v-html="getSvg('unCheckBox')"
                    ></i>
                    <span class="text-[14px] font-[500]">{{
                      column?.title
                    }}</span>
                  </div> -->
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="relative w-full min-w-full">
          <!-- CoveredCallsCollars , EliteCoveredCalls 4/2-->
          <tr
            v-for="(row, rowIndex) in localRowsData"
            :key="rowIndex"
            class="-bg-black border flex h-27"
            :class="row?.id == 1 ? 'bg-[#d1fae2]' : 'bg-[white]'"
          >
            <!-- ticker -->
            <td
              scope="col"
              class="py-2 w-[12%] flex flex-1 justify-between items-center text-left text-black -uppercase"
            >
              <div class="flex flex-col gap-1 pl-3">
                <div
                  class="flex text-[15px] leading-[15px] items-center gap-2 tracking-[.5px]"
                  style="font-weight: 550"
                >
                  {{ row?.ticker?.title }}
                  <span
                    class="h-3 w-3"
                    v-html="getSvg(row?.ticker?.shareIcon)"
                  ></span>
                </div>
                <div class="flex items-center gap-1">
                  <span class="text-[12px] leading-[15px]"
                    >${{ row?.ticker?.currentprice }}</span
                  >
                  <div
                    :class="
                      row?.ticker?.change > 0
                        ? 'text-[#037950]'
                        : 'text-red-600'
                    "
                    class="flex !items-center gap-1"
                  >
                    <span
                      v-if="row?.ticker?.change > 0"
                      v-html="getSvg(row?.ticker?.tArrow)"
                    ></span>
                    <span
                      v-if="row?.ticker?.change < 0"
                      v-html="getSvg(row?.ticker?.dArrow1)"
                    ></span>
                    <span class="text-[12px] leading-[15px]">
                      {{ "(" + row?.ticker?.change + "%)" }}
                    </span>
                  </div>
                </div>
                <span
                  class="font-[400] leading-[15px] text-[13px] font-inter"
                  :class="
                    row?.ticker?.id == 1 ? 'text-[#8d8c8c]' : 'text-[#7A7A7A]'
                  "
                  >{{ row?.ticker?.desc }}</span
                >
                <div class="flex w-full items-center gap-1">
                  <span
                    class="text-[#808080] w-2.5 stroke-[#c0c3c6]"
                    v-html="getSvg('calenderMain')"
                  />
                  <span class="text-[11px]">
                    {{ formatedDate(row?.ticker?.date) }}
                  </span>
                </div>
              </div>

              <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
            </td>

            <!-- Earning Date -->
            <!-- <td
              v-if="row?.earningDate?.visible"
              style="width: 11%"
              scope="col"
              class="py-3 w-[auto] text-[14px] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex justify-between items-center text-left text-black -uppercase -bg-white"
            >
              <div
                class="flex items-center justify-center gap-2 px-3 w-full -bg-slate-200"
              >
                <span
                  class="text-[#cecece]"
                  v-html="getSvg('calenderMain')"
                ></span>
                <div class="flex flex-col justify-center items-center">
                  <span
                    class="text-[#000000] font-[400] leading-[14px] text-[14px] font-inter"
                    >{{ getDateOnly(row?.earningDate?.earningdate) }}
                    {{ getMonthName(row?.earningDate?.earningdate) }}</span
                  >
                  <span
                    class="text-[#cecece] font-[400] leading-[14px] text-[12px] font-inter"
                    >{{ getYearOnly(row?.earningDate?.earningdate) }}</span
                  >
                </div>
              </div>
              <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
            </td> -->

            <!-- dividend -->
            <td
              v-if="row?.dividend?.visible"
              style="width: 11%"
              scope="col"
              class="py-3 w-[11%] text-[14px] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex flex-1 justify-between items-center text-left text-black -uppercase -bg-white"
            >
              <div class="flex mx-auto flex-col justify-center gap-2 px-3">
                <span
                  class="text-[#1d1d1d] leading-[15px] -font-[600] text-[13.5px] font-inter"
                  >${{ row?.dividend?.dividend.toFixed(2) }}</span
                >
                <span
                  class="text-[#1d1d1d] leading-[15px] -font-[600] text-[13.5px] font-inter"
                  >{{ row?.dividend?.dividendInPercent.toFixed(2) }}%</span
                >
              </div>

              <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
            </td>
            <!-- rating -->
            <td
              v-if="row?.rating?.visible"
              style="width: 11%"
              scope="col"
              class="py-3 w-[11%] text-[14px] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex flex-1 justify-between items-center text-left text-black -uppercase -bg-white"
            >
              <div
                class="flex mx-auto flex-col justify-center gap-2 -bg-black --px-3"
              >
                <span
                  class="text-[#7A7A7A] font-[400] leading-[15px] text-[12px] font-inter h-14 w-20"
                  v-html="getSvg(row?.rating?.rating)"
                ></span>
              </div>

              <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
            </td>
            <!-- fv -->
            <td
              v-if="row?.fv?.visible"
              scope="col"
              class="py-3 w-[11%] text-[14px] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex flex-1 justify-end items-center text-left text-black -uppercase -bg-white"
            >
              <div class="flex mx-auto items-center pt-2.5 gap-2 px-3">
                <!-- Less then and !== -->
                <span
                  class="px-3 w-[90px] gap-1.5 py-0.5 rounded-full text-[#056040] text-[13px] flex items-center justify-start bg-[#D1FAE2]"
                  v-if="
                    row?.fv?.fv !== null || row?.fv?.currentprice < row?.fv?.fv
                  "
                >
                  <span
                    class="w-4 fill-[#056040]"
                    v-html="getSvg('checkIcon1')"
                  ></span>
                  <span
                    class="w-auto px-0.5 h-auto rounded-full flex justify-center items-center -bg-red-400"
                  >
                    {{ row?.fv?.fv.toFixed(2) }}
                  </span>
                </span>
                <!-- Lessthen and == null -->
                <span
                  class="px-3 w-[90px] gap-1.5 h-7 rounded-full text-[#9E1616] text-[13px] flex items-center justify-start bg-[#FFE1E1]"
                  v-if="
                    row?.fv?.fv === null || row?.fv?.currentprice > row?.fv?.fv
                  "
                >
                  <span v-html="getSvg('crossIcon1')"></span>
                  <span
                    class="w-auto px-0.5 h-auto rounded-full flex justify-center items-center -bg-red-400"
                  >
                    {{ row?.fv?.fv ? row?.fv?.fv.toFixed(2) : "N/A" }}
                  </span>
                </span>
              </div>
              <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
            </td>
            <!-- treade -->
            <td
              v-if="row?.trade?.visible"
              scope="col"
              class="py-3 w-[15%] text-[14px] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex flex-1 justify-center items-center text-center text-black -uppercase -bg-white"
            >
              <div class="w-full flex justify-center items-center gap-4">
                <div class="flex flex-col gap-2 items-center">
                  <!-- Call -->
                  <div class="flex items-center gap-2">
                    <div
                      class="bg-[#FFE1E1] w-[48px] h-[19px] rounded-full flex justify-center items-center"
                    >
                      <span
                        class="text-[#9E1616] font-[800] text-[10px] font-inter"
                        >SELL</span
                      >
                    </div>
                    <span
                      class="text-[#000000] font-[500] leading-[15px] text-[11px] font-inter"
                      >{{ formatDate(row?.trade?.sellDate) }}</span
                    >
                  </div>
                  <!-- Call -->
                  <div class="flex items-center gap-2">
                    <div
                      class="bg-[#FFE1E1] w-[48px] h-[19px] rounded-full flex justify-center items-center"
                    >
                      <span
                        class="text-[#9E1616] font-[800] text-[10px] font-inter"
                        >SELL</span
                      >
                    </div>

                    <span
                      class="text-[#000000] font-[500] leading-[15px] text-[11px] font-inter"
                      >{{ formatDate(row?.trade?.sellDate) }}</span
                    >
                  </div>
                  <!-- Put -->
                  <div class="flex items-center gap-2">
                    <div
                      class="bg-[#D1FAE2] w-[48px] h-[19px] rounded-full flex justify-center items-center"
                    >
                      <span
                        class="text-[#037950] font-[800] text-[10px] font-inter"
                        >BUY</span
                      >
                    </div>
                    <span
                      class="text-[#000000] font-[500] leading-[15px] text-[11px] font-inter"
                      >{{ formatDate(row?.trade?.sellDate) }}</span
                    >
                  </div>
                </div>
                <div class="flex flex-col gap-1 items-center">
                  <!-- call -->
                  <div class="flex items-center gap-2">
                    <span
                      class="text-[#3EAF3F] font-[800] text-[10px] font-inter"
                      >CALL</span
                    >
                    <span
                      class="text-[#000000] font-[500] leading-[15px] text-[11px] font-inter"
                      ><sup>$</sup
                      >{{ row?.trade?.call1 ? row?.trade?.call1 : "N/A" }}</span
                    >
                  </div>
                  <!-- call -->
                  <div class="flex items-center gap-2">
                    <span
                      class="text-[#3EAF3F] font-[800] text-[10px] font-inter"
                      >CALL</span
                    >
                    <span
                      class="text-[#000000] font-[500] leading-[15px] text-[11px] font-inter"
                      ><sup>$</sup
                      >{{ row?.trade?.call2 ? row?.trade?.call2 : "N/A" }}</span
                    >
                  </div>
                  <!-- put -->
                  <div class="flex items-center gap-2">
                    <span
                      class="text-[#9E1616] font-[800] text-[10px] font-inter"
                      >PUT</span
                    >
                    <span
                      class="text-[#000000] font-[500] leading-[15px] text-[11px] font-inter"
                      ><sup>$</sup
                      >{{
                        row?.trade?.putPrice ? row?.trade?.putPrice : "N/A"
                      }}</span
                    >
                  </div>
                </div>
              </div>

              <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
            </td>
            <!-- max and min rate of return% -->
            <td
              v-if="row?.maxAndMinRateOfReturn?.visible"
              scope="col"
              class="py-3 w-[12%] text-[14px] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex flex-1 justify-center items-center !text-center text-black -uppercase -bg-white"
            >
              <div class="flex w-full flex-col -bg-slate-300 px-3 -bg-blue-800">
                <!-- Max Rate Of Return -->
                <div
                  class="w-full flex flex-col justify-center gap-[-2px] -bg-green-700"
                >
                  <span
                    class="text-[8px] text-[#037950] -bg-slate-100 leading-0 h-4 font-[600]"
                    >MAX RATE OF RETURN</span
                  >

                  <div class="flex justify-center items-center gap-1.5">
                    <div class="flex items-center gap-0.5">
                      <span class="w-3 h-3" v-html="getSvg('clock')"></span>
                      <span
                        class="text-[#1d1d1d] font-[400] text-[9px] font-inter"
                        >{{
                          getRemainingDays(row?.maxRateOfReturn?.expMaxDate)
                        }}
                        days</span
                      >
                    </div>
                    <span
                      class="text-[#037950] font-[550] text-[9px] font-inter"
                      >{{
                        row?.maxAndMinRateOfReturn?.maxratereturn
                          ? row?.maxAndMinRateOfReturn?.maxratereturn.toFixed(2)
                          : "N/A"
                      }}%</span
                    >
                  </div>
                </div>

                <!-- Min Rate Of Return -->
                <div
                  class="w-full -bg-slate-300 flex flex-col gap-[-2px] justify-center"
                >
                  <span
                    class="text-[8px] text-[#9E1616] -bg-slate-100 leading-0 h-4 font-[400]"
                    >MIN RATE OF RETURN</span
                  >

                  <div class="flex justify-center items-center gap-1.5">
                    <div class="flex gap-0.5 items-center w-auto">
                      <span class="w-3 h-3" v-html="getSvg('clock')"></span>
                      <span
                        class="text-[#1d1d1d] font-[400] text-[9px] font-inter"
                        >{{
                          getRemainingDays(
                            row?.maxAndMinRateOfReturn?.expMinDate
                          )
                        }}
                        days</span
                      >
                    </div>
                    <span
                      class="text-[#9E1616] font-[550] text-[9px] font-inter"
                      >{{
                        row?.maxAndMinRateOfReturn?.minratereturn
                          ? row?.maxAndMinRateOfReturn?.minratereturn.toFixed(2)
                          : "N/A"
                      }}%</span
                    >
                  </div>
                </div>
              </div>
              <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
            </td>

            <!-- chart -->
            <td
              style=""
              scope="col"
              class="py-3 text-[14px] w-[8%] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex flex-auto justify-center -bg-black items-center -text-left text-black -uppercase -bg-yellow-500"
            >
              <div class="flex w-full -bg-green-300 flex-col gap-2 px-3">
                <div
                  class="w-full flex-wrap -bg-slate-300 h-[70px] flex justify-center items-center"
                >
                  <!-- <AreaChart /> -->
                  <img
                    class=""
                    :src="`https://incometradercharts.com/fmgraphttransparent.ashx?h=80&w=360&bw=3&sr=true&s=${row?.ticker?.title}&int=true`"
                    alt=""
                  />
                </div>
              </div>
            </td>
          </tr>

          <div
            v-if="!isLoading && this.localRowsData.length < 1"
            class="flex justify-center items-center -bg-[#ECECEC] w-full h-[600px]"
          >
            <h1 class="text-[20px]">No result found!</h1>
          </div>
          <div
            v-if="isLoading"
            class="flex bg-white/50 justify-center items-center -absolute fixed top-[171px] w-[82%] h-[620px]"
          >
            <span class="loader"></span>
          </div>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { getSvgByName } from "../../assets/svgs/CurrentUptrend.js";
import ColumnModal from "../../components/ColumnOptionModal/index.vue";
import TooltipVue from "../ToolTipVue/TooltipVue.vue";
import ChartEvent from "@/eventBus.js";
import moment from "moment";
export default {
  name: "GBAScanner",
  data() {
    return {
      showDropdown: false,
      isEnter: false,
      formattedTime: "",
      hiddenColumns: [],
      remainingDays: null,
      isCardView: false,
      isActiveScanner: "GoodStocks",
      isActive: "Good Stocks",
      allData: [],
      currentPage: 1,
      pageStart: 1,
      pageEnd: 50,
      itemsPerPage: 50,
      localColumnsData: JSON.parse(JSON.stringify(this.columnsData)), // Deep copy of columnsData
      localRowsData: JSON.parse(JSON.stringify(this.rowsData)), // Deep copy of rowsData
    };
  },
  components: {
    ColumnModal,
    TooltipVue,
  },

  props: {
    rowsData: {
      type: Array,
      required: true,
    },
    columnsData: {
      type: Array,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: "#F5F5F5",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatedDate(val) {
      console.log("val", val);
      return val === "N/A" ? "N/A" : moment(val).format("MM/DD/YYYY");
    },
    getSvg(name) {
      return getSvgByName(name);
    },

    getDateOnly(dateString) {
      const date = new Date(dateString);
      return isNaN(date) ? "N/A" : date.getDate(); // Returns only the day of the month (1-31)
    },

    getMonthName(dateString) {
      const date = new Date(dateString);
      return isNaN(date)
        ? "N/A"
        : date.toLocaleString("default", { month: "short" }); // Returns the full month name
    },

    getYearOnly(dateString) {
      const date = new Date(dateString);
      return isNaN(date) ? "N/A" : date.getFullYear(); // Returns only the year (e.g., 2025)
    },

    /*************  ✨ Codeium Command ⭐  *************/
    /**
     * Calculates the number of days remaining until the given date
     * @param {string} dateStr - The date string in the format "YYYY-MM-DD"
     * @returns {number} The number of days remaining
     */
    /******  438781cc-9fac-40f2-b0d2-73de1073cf53  *******/
    getRemainingDays(dateStr) {
      // If date is missing or invalid, return "N/A"
      if (!dateStr || !moment(dateStr).isValid()) {
        return "N/A";
      }

      // Calculate remaining days
      const remainingDays = moment(dateStr).diff(moment(), "days");

      // Return days or 0 if the date has passed
      return Math.max(remainingDays, 0);
    },

    formatDate() {
      const date = moment("2024-12-01", "YYYY-MM-DD");

      // Format the date as "DD/MM"
      const formattedDate = date.format("DD/MM");
      return formattedDate;
    },

    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
      // alert(this.rowsData[0]?.ticker?.title);
      console.log("toggleDropdown", this.rowsData);
    },
    toggleColumn(index) {
      this.columns[index].visible = !this.columns[index].visible;
      this.rowsData?.map((row) => {
        row[`column${index + 1}`].visible = this.columns[index].visible;
      });
    },
    // datalo() {
    //   this.allData = this.rowsData
    // },
  },
  computed: {
    //  this.datalo()
    // atalo() {
    //   console.log("this.rowsData", this.rowsData);
    //   this.allData = this.$props.rowsData
    //   return this.allData;
    // },
  },
  mounted() {
    ChartEvent.$on("toggleColumn", (index) => {
      // alert(index);

      // Toggle visibility for the column
      this.localColumnsData[index].visible =
        !this.localColumnsData[index].visible;

      // Toggle visibility for the corresponding row data
      this.localRowsData.forEach((row) => {
        console.log(row);

        const columnKeys = Object.keys(row); // Get all keys in the row object

        if (columnKeys[index]) {
          // Toggle visibility for the specific column
          row[columnKeys[index]].visible = !row[columnKeys[index]].visible;
          console.log(
            `Toggled visibility for column: ${row[columnKeys[index]].visible}`
          );
        } else {
          console.warn(`No column found at index ${index}`);
        }
      });

      // alert(this.localColumnsData[index].visible);
      // alert("hello");
    });
  },
  beforeDestroy() {
    ChartEvent.$off("toggleColumn"); // Use $off to clean up the event listener
  },
  watch: {
    columnsData: {
      handler(newVal) {
        this.localColumnsData = JSON.parse(JSON.stringify(newVal)); // Sync with prop changes
      },
      deep: true,
    },
    rowsData: {
      handler(newVal) {
        this.localRowsData = JSON.parse(JSON.stringify(newVal));
        console.log("bye", this.localRowsData);
      },
      deep: true,
    },
  },
};
</script>

<style></style>
