<template>
  <div
    class="w-full h-full gap-4 py-4 flex flex-col items-center bg-white overflow-y-auto"
  >
    <!-- <div
      
      class="w-full h-full flex flex-col py-2 justify-center items-center gap-0.5 font-[400] text-[16px] leading-[14.52px] tracking-[0.2] -bg-[#6D6D6D]"
    >
      <div
        class="w-[93%] text-[#6D6D6D] cursor-pointer mrrounded-xl px-4 h-[40px] hover:bg-[#A8D6C14D] items-center overflow-hidden flex gap-3 justify-center font-[400] text-[15px] leading-[14.52px] tracking-[0.2] bg-[#ffffff]"
      >
        <span class="cursor-pointer" v-html="getSvg('stars')"></span>
        <span
          class="w-[85%] stracking-[0.5px] pr-1 py-2 text-[#555F6D] text-[13px] ring-0"
          >Symbol Lookup</span
        >
        <span
          v-if="item?.newTitle"
          class="text-[#D8A60F] bg-[#FFB3002E] rounded-2xl text-[12px]  px-3 py-[3px]"
          >New</span
        >
      </div>
    </div> -->
    <div
      class="justify-between w-[94%] py-2 px-4 cursor-pointer flex gap-2 -my-[5px] hover:bg-[#A8D6C14D] items-center"
    >
      <div class="flex gap-3 items-center">
        <span class="h-4 w-5" v-html="getSvg('stars')"></span>
        <span class="text-[12px] font-Inter text-[#555F6D]">Symbol Lookup</span>
      </div>
      <!-- <div class="bg-red-400 flex justify-end items-end self-end text-right"> -->
      <span
        class="text-[#D8A60F] text-right bg-[#FFB3002E] rounded-2xl text-[12px] px-3 py-[1px]"
        >New</span
      >
      <!-- </div> -->
    </div>

    <!-- Stock Quote -->
    <div class="w-full h-auto">
      <LeftBarComp
        :backgroundColor="backgroundColor"
        :title="'Stock Quote & Stock Evaluator ®'"
      />
    </div>

    <!-- portfolio management -->
    <div class="w-full h-auto">
      <LeftBarComp
        :backgroundColor="backgroundColor"
        :btnData="portfolioManagement"
        :title="'Portfolio Management'"
      />
    </div>

    <!-- current article -->
    <div class="w-full h-auto">
      <LeftBarComp
        :backgroundColor="backgroundColor"
        :btnData="currentArticles"
        :title="'Current Articles'"
      />
    </div>

    <!-- past article -->
    <div class="w-full h-auto">
      <LeftBarComp
        :backgroundColor="backgroundColor"
        :btnData="pastArticles"
        :title="'Past Articles'"
      />
    </div>

    <!-- website information -->
    <div class="w-full h-auto">
      <LeftBarComp
        :backgroundColor="backgroundColor"
        :btnData="websiteInformation"
        :title="'Website Information'"
      />
    </div>

    <!-- New Features Detail Here -->
    <div
      class="w-[90%] h-[280px] rounded-xl flex flex-col gap-2 mt-5 p-4 bg-[#EBF1EE]"
    >
      <div
        class="w-full h-[30px] flex justify-between gap-3 font-inter text-[16px] tracking-[0.2] text-[#6D6D6D]"
      >
        <span class="text-[#101828] text-[13px] font-[600] leading-[20px]"
          >New features available!</span
        >
        <span class="cursor-pointer" v-html="getSvg('cross')"></span>
      </div>
      <div class="w-full h-[190px] justify-between flex flex-col">
        <span
          class="w-full flex justify-center items-center font-inter text-[13px] tracking-[0.3px] text-[#475467]/75 -mt-3"
        >
          Check out the new dashboard view. Pages now load faster.
        </span>
        <div class="w-full h-[220px] py-4 flex justify-center items-center">
          <span class="!w-full !h-full" v-html="getSvg('newfeatures')"></span>
        </div>
      </div>
      <div class="w-full flex gap-4 font-[600] text-[14px] leading-[18px]">
        <span class="cursor-pointer text-[#475467]">Dismiss</span>
        <span class="text-[#134479] cursor-pointer">What’s new?</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getSvgByName } from "../../assets/svgs/Leftbar.js";
import LeftBarComp from "../left-bar-components/LeftBarComp.vue";

export default {
  name: "NavBar",

  data() {
    return {
      portfolioManagement: [
        {
          id: 1,
          title: "Watchlist",
          icon: "watchlist",
        },
        {
          id: 2,
          title: "Asset Allocation",
          icon: "assetallocation",
        },
        {
          id: 3,
          title: "Asset Management",
          icon: "assetmanagement",
        },
      ],

      currentArticles: [
        {
          id: 1,
          title: "Overview",
          newTitle: "New",
          icon: "overview",
        },
        {
          id: 2,
          title: "Commentary",
          icon: "commentary",
        },
        {
          id: 3,
          title: "Covered Calls",
          icon: "coveredcalls",
        },
        {
          id: 4,
          title: "Stock Picks",
          icon: "stockpicks",
        },
        {
          id: 5,
          title: "Spreads",
          icon: "spreads",
        },
        {
          id: 6,
          title: "Learning Curve",
          icon: "learningcurve",
        },
        {
          id: 7,
          title: "LEAPS Plays",
          icon: "leapsplays",
        },
        {
          id: 8,
          title: "Homework Followup",
          icon: "homeworkfollowup",
        },
        {
          id: 9,
          title: "Coval Trend Indicator",
          icon: "covaltrendindicator",
        },
        {
          id: 10,
          title: "Technical Talk",
          icon: "technicaltalk",
        },
      ],

      pastArticles: [
        {
          id: 1,
          title: "Technical Talk",
          icon: "technicaltalk",
        },
        {
          id: 2,
          title: "Learning Curve",
          icon: "learningcurve",
        },
        {
          id: 3,
          title: "Glossary",
          icon: "glossary",
        },
      ],

      websiteInformation: [
        {
          id: 1,
          title: "Video Tour",
          icon: "videotour",
        },
        {
          id: 2,
          title: "Upcoming Events",
          icon: "upcommingevents",
        },
        {
          id: 3,
          title: "Contact Us",
          icon: "contactus",
        },
      ],
    };
  },
  props: {
    backgroundColor: String,
  },
  components: {
    LeftBarComp,
  },

  methods: {
    getSvg(name) {
      return getSvgByName(name);
    },
  },
};
</script>

<style>
input::-webkit-input-placeholder {
  font-size: 15px;
  font: 900 !important;
  color: #6d6d6d !important;
}

/* Global scrollbar styling for WebKit browsers */
::-webkit-scrollbar {
  width: 5px; /* Width of vertical scrollbar */
  height: 12px; /* Height of horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar handle */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of handle when hovered */
}
</style>
